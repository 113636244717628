import { Form, FormikProvider, useFormik } from 'formik';
import { Button, Form as RBForm, Modal } from 'react-bootstrap';
import { Form as Formik } from 'react-bootstrap-formik';
import { useQueryClient } from 'react-query';
import {
  changeInvoiceStatus,
  INVOICE_STATUS,
} from '../../../api/AdminInvoicesAPI';

export function RequiresAttentionModal({ show, invoiceId, handleClose }) {
  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues: { comment: '' },
    onSubmit({ comment }) {
      return changeInvoiceStatus({
        invoiceId,
        comment,
        invoiceStatusId: INVOICE_STATUS.REQUIRES_ATTENTION, // Reviewed
      }).then(() => {
        queryClient.invalidateQueries(['invoices']);
        handleClose();
      });
    },
  });

  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <FormikProvider value={formik}>
        <Form>
          <Modal.Header
            closeButton
            style={{ background: '#396cae', color: '#fff' }}
          >
            <Modal.Title>
              <h3 className="p-0 m-0">
                Insert the details that will be sent to Carrier
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RBForm.Group className="mb-2">
              <RBForm.Label>
                <b>Paperwork needed</b>
              </RBForm.Label>
              <Formik.Textarea
                name="comment"
                placeholder="Enter your comments"
              />
            </RBForm.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            <Button type="submit" className="invoice-btn">
              Send
            </Button>
          </Modal.Footer>
        </Form>
      </FormikProvider>
    </Modal>
  );
}
