import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCarriersAction } from '../../actions/carrier';
import { setCurrentPage } from '../../reducers/carrierReducer';
import '../admcarriers/AdmCarriers.scss';
import MyAction from './MyAction';

function AdmCarriers(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.carrier.currentPage);
  const { items: carriers, totalPages } = useSelector((state) => state.carrier);

  useEffect(() => {
    dispatch(getCarriersAction(currentPage));
  }, [currentPage, dispatch]);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#930306',
      },
      secondary: {
        main: '#930306',
      },
    },
  });

  return (
    <>
      <div className="pl-3" style={{ margin: '0px 10px' }}>
        <div className="row">
          <div className="p-0 m-0">
            <MuiThemeProvider theme={theme}>
              <MaterialTable
                title={<h2 style={{ margin: '1.5rem 0rem' }}>Carriers</h2>}
                columns={[
                  { title: 'id', field: 'id', hidden: true },
                  { title: 'Business Name', field: 'businessName' },
                  { title: 'Owner', field: 'owner' },
                  { title: 'Email', field: 'email' },
                  { title: 'Factoring', field: 'factoringFee' },
                ]}
                totalCount={totalPages * 10}
                page={currentPage}
                onChangePage={(page) => {
                  dispatch(setCurrentPage(page));
                }}
                data={carriers}
                onRowClick={(evt, selectedRow) => {
                  history.push(`/carrier/${selectedRow.id}`);
                }}
                options={{
                  pageSizeOptions: [],
                  selection: true,
                  pageSize: 10,
                  maxBodyHeight: `72vh`,
                  minBodyHeight: `72vh`,
                }}
                components={{
                  Action: (props) => <MyAction {...props} />,
                  Toolbar: (props) => (
                    <div style={{ color: '#676a6c' }}>
                      <MTableToolbar {...props} />
                    </div>
                  ),
                }}
                actions={[
                  {
                    tooltip: 'Remove All Selected Rows',
                    icon: 'delete',
                    onClick: (evt, data) => {
                      const conf = window.confirm(`Are you sure?`);
                      if (conf) {
                        //TO DO:
                      }
                    },
                  },
                  {
                    icon: () => (
                      <Button
                        className="invoice-btn"
                        onClick={() => history.push('/carrier')}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Add New Carrier
                      </Button>
                    ),
                    isFreeAction: true,
                    tooltip: 'Add New Carrier',
                  },
                ]}
                localization={{
                  toolbar: { searchPlaceholder: 'Search for a Carrier' },
                }}
              />
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdmCarriers;
