import jwt_decode from 'jwt-decode';
import { $authHost } from '../api/config';
import {
  setDrivers,
  setFetchError,
  setIsFetching,
} from '../reducers/driverReducer';

export const driversOptions = async (carrierID) => {
  const token = sessionStorage.getItem('token');
  const jwtInfo = jwt_decode(token);
  const response = await $authHost.get(
    `/api/carrier/${carrierID || jwtInfo.id}/driver`
  );

  if (response.status === 200) {
    return response.data;
  }
};

export const getDriversAction = (carrierID) => {
  return async (dispatch) => {
    try {
      dispatch(setIsFetching(true));
      const response = await $authHost.get(`/api/carrier/${carrierID}/driver`);
      dispatch(setDrivers(response.data));
    } catch (e) {
      dispatch(setFetchError(true));
      dispatch(setIsFetching(false));
      setTimeout(() => {
        dispatch(setFetchError(false));
      }, 2000);
    }
  };
};

export const getSelectedDriverAction = async (carrierID, driverID) => {
  try {
    const response = await $authHost.get(
      `/api/carrier/${carrierID}/driver/${driverID}`
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return e.response.data;
  }

  return {};
};

export const addDriverAction = async (carrierID, payload) => {
  try {
    const response = await $authHost.post(
      `/api/carrier/${carrierID}/driver`,
      JSON.stringify(payload),
      {
        headers: {
          'Content-Type': ' application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return null;
  }
};

export const changeDriverAction = async (carrierID, driverID, payload) => {
  try {
    const response = await $authHost.put(
      `/api/carrier/${carrierID}/driver/${driverID}`,
      JSON.stringify(payload),
      {
        headers: {
          'Content-Type': ' application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return null;
  }
};

export const deleteDriverAction = async (carrierID, driverID) => {
  try {
    const response = await $authHost.delete(
      `/api/carrier/${carrierID}/driver/${driverID}`,
      {
        headers: {
          'Content-Type': ' application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    alert('Error to delete driver');
    return {};
  }
};
