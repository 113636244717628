import Select from 'react-select';
import { useBrokers } from '../../../api/AdminInvoicesAPI';

export function BrokerSelect({ value, onChange }) {
  const { data = [] } = useBrokers();

  return (
    <Select
      isClearable
      options={data}
      onChange={onChange}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.debtorCompanyName}
      value={data.find((item) => item.id === value)}
      theme={(theme) => ({
        ...theme,
        borderRadius: '.25rem',
        colors: {
          ...theme.colors,
          primary25: '#f3f3f3',
          primary: '#396cae',
        },
      })}
    />
  );
}
