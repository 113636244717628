import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { documentTypeOptions } from '../actions/directory';
import { addDocumentsAction } from '../actions/signDocuments';

const styleZone = {
  border: '2px dashed  hsl(0, 0%, 80%)',
  padding: '3rem',
  marginBottom: '1rem',
  textAlign: 'center',
  color: '#676a6c',
  fontSize: '16px',
};

const loadDocumentTypeOptions = async () => {
  const response = await documentTypeOptions();
  const data = response.data;
  const optionList =
    data &&
    data.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  return optionList;
};

const DropFileContainer = (props) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [typeFile, setTypeFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  });

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
  });

  const fileUpload = files.map((file) => (
    <li key={file.path}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const handleCancelClick = () => {
    setFiles([]);
    setTypeFile(null);
  };

  const handleUploadFileClick = () => {
    dispatch(addDocumentsAction(props.id, typeFile.value, files[0]));
    setFiles([]);
    setTypeFile(null);
  };

  return (
    <section className="container-fluid" style={{ display: 'flex' }}>
      <div className="form-uplad" style={{ flex: '3 80%' }}>
        <div {...getRootProps({ className: 'dropzone', style: styleZone })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <AsyncSelect
              cacheOptions
              loadOptions={loadDocumentTypeOptions}
              defaultOptions
              onChange={(value) => setTypeFile(value)}
              value={typeFile}
              placeholder="search type document"
              theme={(theme) => ({
                ...theme,
                borderRadius: '.25rem',
                colors: {
                  ...theme.colors,
                  primary25: '#f3f3f3',
                  primary: '#396cae',
                },
              })}
            />
          </div>
          <div className="col-sm-6">
            <div className="input-group pull-right">
              <Button
                className="m-1"
                variant="light"
                onClick={() => handleCancelClick()}
              >
                Cancel
              </Button>
              <Button
                className="invoice-btn m-1"
                onClick={() => handleUploadFileClick()}
                disabled={!(typeFile && files && files.length > 0)}
              >
                <FontAwesomeIcon icon={faUpload} /> Upload
              </Button>
            </div>
          </div>
        </div>
      </div>
      {files && (
        <div className="files-upload" style={{ flex: '1 20%' }}>
          <h6 style={{ textAlign: 'center' }}>Files to Upload</h6>
          <ol>{fileUpload}</ol>
        </div>
      )}
    </section>
  );
};

export default DropFileContainer;
