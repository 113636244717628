import axios from 'axios';
import queryString from 'query-string';

const API_URL = 'https://api.bm.topbrain.it';
//const API_URL = 'https://hand-ceramic.club'
const $host = axios.create({
  baseURL: API_URL,
});

const $authHost = axios.create({
  baseURL: API_URL,
  paramsSerializer: function (params) {
    return queryString.stringify(params, { arrayFormat: 'index' });
  },
});

const authInterceptor = (config) => {
  config.headers.authorization = `Bearer ${sessionStorage.getItem('token')}`;
  return config;
};

$authHost.interceptors.request.use(authInterceptor);
$authHost.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    //catches if the session ended!
    const status_code = error.response ? error.response.status : null;
    switch (status_code) {
      case 400:
        const errors = error.response.data.errors || null;
        const A =
          errors &&
          errors.map((item) => `\r\n${item.field} : ${item.defaultMessage}`);
        alert(`${error.response.data.message}. ${A ? A.join('; ') : ''}`);
        break;
      case 403:
        alert(error.response.data.message);
        sessionStorage.clear();
        window.location.pathname = './';
        break;
      case 404:
        alert('Please check the correctness of the entered data');
        break;
      default:
        alert(error.message);
        break;
    }

    return Promise.reject(error);
  }
);

export { $host, $authHost };
