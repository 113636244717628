import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import {
  addBrockerAction,
  changeBrockerAction,
  deleteBrockerAction,
  getBrokersAction,
} from '../../actions/brokerCredentials';

const columns = [
  { title: 'id', field: 'id', hidden: true },
  { title: 'Broker Name', field: 'brokerName', cellStyle: { width: '30%' } },
  { title: 'Website', field: 'website', cellStyle: { width: '20%' } },
  { title: 'Username', field: 'username', cellStyle: { width: '20%' } },
  { title: 'Password', field: 'password', cellStyle: { width: '20%' } },
];

const theme = createTheme({
  palette: {
    primary: {
      main: '#930306',
    },
    secondary: {
      main: '#930306',
    },
  },
});

function BrokerCredentialGrid(props) {
  const carrierId = props.carrierId;
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (carrierId) {
        const response = await getBrokersAction(carrierId);
        setData(response.data);
      }
    }
    fetchData();
  }, [carrierId]);

  const handleAdd = async (newData) => {
    const result = await addBrockerAction(carrierId, newData);
    setData([...data, result]);
  };

  const handleChange = async (newData, oldData) => {
    const brokerId = oldData.id;
    await changeBrockerAction(carrierId, brokerId, newData);
    const dataUpdate = [...data];
    const index = oldData.tableData.id;
    dataUpdate[index] = newData;
    setData([...dataUpdate]);
  };

  const handleDelete = async (oldData) => {
    const brokerId = oldData.id;
    await deleteBrockerAction(carrierId, brokerId);

    const dataDelete = [...data];
    const index = oldData.tableData.id;
    dataDelete.splice(index, 1);
    setData([...dataDelete]);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        style={{ boxShadow: 'none' }}
        title="Broker Credential"
        columns={columns}
        data={data}
        options={{
          actionsColumnIndex: -1,
        }}
        editable={{
          onRowAdd: (newData) => handleAdd(newData),
          onRowUpdate: (newData, oldData) => handleChange(newData, oldData),
          onRowDelete: (oldData) => handleDelete(oldData),
        }}
      />
    </MuiThemeProvider>
  );
}

export default BrokerCredentialGrid;
