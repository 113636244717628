import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import carrierReducer from './carrierReducer';
import driverReducer from './driverReducer';
import invoceReducer from './invoceReducer';
import signDocumentsReducer from './signDocumentsReducer';

const rootReducers = combineReducers({
  carrier: carrierReducer,
  carrierDocs: signDocumentsReducer,
  driver: driverReducer,
  invoice: invoceReducer,
});

export const store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(thunk))
);
