import { $authHost } from '../api/config';
import {
  addSignDocuments,
  deleteSignDocuments,
  setFetchError,
  setIsFetching,
  setSignDocuments,
} from '../reducers/signDocumentsReducer';

export const getDocumentsAction = (carrierId) => {
  return async (dispatch) => {
    try {
      dispatch(setIsFetching(true));
      const response = await $authHost.get(
        `/api/carrier/${carrierId}/signed_document`
      );
      dispatch(setSignDocuments(response));
    } catch (e) {
      dispatch(setFetchError(true));
      dispatch(setIsFetching(false));
      setTimeout(() => {
        dispatch(setFetchError(false));
      }, 2000);
    }
  };
};

export const deleteDocumentsAction = (carrierId, uuid) => {
  return async (dispatch) => {
    try {
      dispatch(setIsFetching(true));
      const response = await $authHost.delete(
        `/api/carrier/${carrierId}/signed_document/${uuid}`
      );
      dispatch(deleteSignDocuments(response.data.entityId));
    } catch (e) {
      dispatch(setFetchError(true));
      dispatch(setIsFetching(false));
      setTimeout(() => {
        dispatch(setFetchError(false));
      }, 2000);
    }
  };
};

export const downLoadDocumentsAction = async (carrierId, uuid) => {
  const response = await $authHost.get(
    `/api/carrier/${carrierId}/signed_document/${uuid}`,
    {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
      },
    }
  );
  const data = response.data;
  if (!(data instanceof Blob)) return;

  const filename = 'File';
  const blob = new Blob([data], { type: data.type });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${filename}-${+new Date()}.pdf`;
  link.click();
};

export const addDocumentsAction = (carrierId, signedDocumentTypeId, file) => {
  return async (dispatch) => {
    try {
      dispatch(setIsFetching(true));
      const formData = new FormData();
      formData.append('file', file);
      const response = await $authHost.post(
        `/api/carrier/${carrierId}/signed_document/type/${signedDocumentTypeId}`,
        formData
      );
      dispatch(addSignDocuments(response.data));
    } catch (e) {
      dispatch(setFetchError(true));
      dispatch(setIsFetching(false));
      setTimeout(() => {
        dispatch(setFetchError(false));
      }, 2000);
    }
  };
};
