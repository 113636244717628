import { useMutation, useQuery, useQueryClient } from 'react-query';
import { diffDate } from '../utils/DateUtils';
import { $authHost } from './config';

export const INVOICE_STATUS = {
  SUBMITTED: 3,
  REVIEWED: 4,
  REQUIRES_ATTENTION: 5,
  VERIFIED: 6,
  APPROVED: 7,
  DELAYED_ADVANCE: 8,
  REJECTED: 9,
  CHARGEBACK: 16,
  OVER_PAY: 15,
  SHORT_PAY: 14,
  INVOICED: 11,
  CHECK_MAILED: 12,
  CREATED: 1,
  PAID: 13,
};

export function useAdminInvoices(params) {
  return useQuery(
    ['invoices', 'list', params],
    async () => {
      const { data } = await $authHost.get('/api/invoice', { params });
      data.data.sort((a, b) => diffDate(b.createAt, a.createAt));
      return data;
    },
    {
      refetchOnMount: 'always',
      keepPreviousData: false,
    }
  );
}

export function useInvoiceHistory(id) {
  return useQuery(['invoices', 'history', { id }], async () => {
    const { data } = await $authHost.get(`/api/invoice/${id}/history`);
    return data;
  });
}

export async function downloadInvoiceFile(carrierId, fileId) {
  const { data } = await $authHost.get(
    `/api/carrier/${carrierId}/file/${fileId}`,
    {
      responseType: 'blob',
    }
  );
  return data;
}

export async function changeInvoiceStatus(params) {
  return $authHost.post('/api/flow/admin/change_status', params);
}

export async function brokerPaid(invoiceId, amount) {
  return $authHost.put(`/api/invoice/${invoiceId}/broker_paid`, {
    brokerPaid: amount,
  });
}

export async function sendBroker(invoiceId, emailList) {
  return $authHost.post(`/api/flow/admin/send_to_broker`, {
    invoiceId,
    emailList,
  });
}

export async function commentInvoice(id, comment) {
  return $authHost.post('/api/flow/comment', {
    invoiceId: id,
    comment,
  });
}

export async function addInvoiceFile(id, fileType, file) {
  const formData = new FormData();
  formData.append('file', file);

  return $authHost.post(`/api/invoice/${id}/file`, formData, {
    params: { fileType },
  });
}

export function useChangeInvoiceStatus() {
  const queryClient = useQueryClient();
  return useMutation(changeInvoiceStatus, {
    onSuccess() {
      queryClient.invalidateQueries(['invoices']);
    },
  });
}

export function useChangeInvoiceStatusBulk(onSuccess) {
  const queryClient = useQueryClient();
  return useMutation(
    (invoices) => {
      return Promise.all(
        invoices.map((invoice) => changeInvoiceStatus(invoice))
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['invoices']);
        onSuccess();
      },
    }
  );
}

export function useCarriers() {
  return useQuery(['invoices', 'carriers'], async () => {
    const { data } = await $authHost.get('/api/carrier');
    return data.data;
  });
}

export function useBrokers() {
  return useQuery(['invoices', 'brokers'], async () => {
    const { data } = await $authHost.get('/api/broker');
    return data.data;
  });
}
