const SET_SIGN_DOCUMENTS = 'SET_SIGN_DOCUMENTS';
const SET_IS_FETCHING = 'SET_IS_FETCHING';
const SET_FETCH_ERROR = 'SET_FETCH_ERROR';
const ADD_SIGN_DOCUMENTS = 'ADD_SIGN_DOCUMENTS';
const DELETE_SIGN_DOCUMENTS = 'DELETE_SIGN_DOCUMENTS';

const defaultState = {
  items: [],
  isFetching: true,
  isFetchError: false,
};

export default function signDocumentsReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_SIGN_DOCUMENTS:
      return {
        ...state,
        items: action.payload.data,
        isFetching: false,
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    case SET_FETCH_ERROR:
      return {
        ...state,
        isFetchError: action.payload,
      };
    case ADD_SIGN_DOCUMENTS:
      return {
        ...state,
        items: [...state.items, action.payload],
        isFetching: false,
      };
    case DELETE_SIGN_DOCUMENTS:
      return {
        ...state,
        items: state.items.filter((item) => item.uuid !== action.payload),
        isFetching: false,
      };
    default:
      return state;
  }
}

export const setSignDocuments = (docs) => ({
  type: SET_SIGN_DOCUMENTS,
  payload: docs,
});
export const setIsFetching = (bool) => ({
  type: SET_IS_FETCHING,
  payload: bool,
});
export const setFetchError = (bool) => ({
  type: SET_FETCH_ERROR,
  payload: bool,
});
export const addSignDocuments = (doc) => ({
  type: ADD_SIGN_DOCUMENTS,
  payload: doc,
});
export const deleteSignDocuments = (doc) => ({
  type: DELETE_SIGN_DOCUMENTS,
  payload: doc,
});
