import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import useInput from '../../hooks/inputs';

function Charge(props) {
  const { chargeState, setChargeState } = props;
  const [addNewRow, setAddNewRow] = useState(false);

  const description = useInput('', { isEmpty: true });
  const amount = useInput('', { isEmpty: true });

  const handleSaveClick = async () => {
    const data = {
      description: description.value,
      amount: amount.value,
    };
    setChargeState([...chargeState, data]);
  };

  const handleDeleteItem = async (index) => {
    const conf = window.confirm(`Are you sure?`);
    if (conf) {
      setChargeState(chargeState.filter((item, i) => i !== index));
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Form.Label>
          <h6>Other Charges</h6>
        </Form.Label>
        <Button
          className="invoice-btn-two m-1"
          size="sm"
          onClick={() => setAddNewRow(!addNewRow)}
        >
          Add New
        </Button>
      </div>
      {addNewRow ? (
        <div style={{ display: 'inline-flex' }}>
          <Form.Group className="m-1" style={{ flex: '1 1 60%' }}>
            <Form.Control
              type="text"
              onChange={(e) => description.onChange(e)}
              onBlur={(e) => description.onBlur(e)}
              value={description.value}
              placeholder="Description"
            />
            {description.isDirty && description.isEmpty && (
              <div className="error">This is a required</div>
            )}
          </Form.Group>

          <Form.Group className="m-1" style={{ flex: '1 1 40%' }}>
            <Form.Control
              type="text"
              onChange={(e) => amount.onChange(e)}
              onBlur={(e) => amount.onBlur(e)}
              value={amount.value}
              placeholder="Amount"
            />
            {amount.isDirty && amount.isEmpty && (
              <div className="error">This is a required</div>
            )}
          </Form.Group>
          <div style={{ position: 'relative' }}>
            <Button
              className="invoice-btn p-2 mt-1 btn-sm"
              disabled={!(description.isValid && amount.isValid)}
              onClick={() => handleSaveClick()}
            >
              <FontAwesomeIcon icon={faSave} />
            </Button>
          </div>
        </div>
      ) : null}
      <hr />
      <div>
        {chargeState.map((item, index) => (
          <div
            key={index}
            className="w-100 mt-0"
            style={{ display: 'inline-flex' }}
          >
            <p className="m-1 pt-2" style={{ flex: '1 1 60%' }}>
              {item.description}{' '}
            </p>
            <p className="m-1 pt-2" style={{ flex: '1 1 40%' }}>
              {item.amount}
            </p>
            <Button
              variant="light"
              className="m-1 btn-sm"
              onClick={() => handleDeleteItem(index)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        ))}
      </div>
      <hr />
    </>
  );
}

export default Charge;
