import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { downloadInvoiceFile } from '../../../api/AdminInvoicesAPI';

const thumb = {
  color: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 210,
  height: 200,
  boxSizing: 'border-box',
  cursor: 'pointer',
};
const thumbInner = {
  flex: '1 1 auto',
  minWidth: 0,
  overflow: 'hidden',
};
const thumbFooter = {
  background: '#eee',
  height: '50px',
  padding: '5px',
};
const thumbFooter__inline = {
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '.9em',
  paddingTop: '5px',
};

export function InvoiceFileItem({ file, carrierID }) {
  const { id, fileSize, name, createAt, type } = file;
  return (
    <div
      style={thumb}
      onClick={() => {
        downloadInvoiceFile(carrierID, id).then((file) => {
          const anchor = document.createElement('a');
          anchor.href = URL.createObjectURL(file);
          anchor.download = name;
          anchor.click();
        });
      }}
    >
      <div style={thumbInner}>
        <FontAwesomeIcon icon={faFilePdf} /> {type}
      </div>
      <div style={thumbFooter}>
        <b>{name}</b>
        <div style={thumbFooter__inline}>
          <text>{createAt}</text>
          <text>{fileSize}</text>
        </div>
      </div>
    </div>
  );
}
