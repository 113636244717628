import { faLock, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Card, Form, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {
  addCarrierAction,
  changeCarrierAction,
  deleteCarrierAction,
  getSelectedCarrierAction,
} from '../../actions/carrier';
import { statePaymentOptions } from '../../actions/directory';
import { logout } from '../../reducers/currentReducer';
import CarrierSignedDocuments from '../carrierDocuments/CarrierSignedDocuments';
import BrokerCredentialGrid from './BrokerCredentialGrid';

const loadPaymentOptions = async () => {
  const response = await statePaymentOptions();
  const data = response.data;
  const optionList =
    data &&
    data.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  return optionList;
};

function AdmCarrier(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const isChange = id > -1;
  const [currentCarrier, setcurrentCarrier] = useState({});
  const [selectPaymentOptions, setSelectPaymentOptions] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      if (isChange) {
        const responseCarrier = await getSelectedCarrierAction(id);
        if (responseCarrier.status) dispatch(logout());
        else {
          const po = responseCarrier.paymentOptions.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setSelectPaymentOptions(po);
          setcurrentCarrier(responseCarrier);
          reset(responseCarrier);
        }
      }
    };
    fetchData();
  }, [reset]);

  const handleClickCancel = async () => {
    setcurrentCarrier({});
    history.push('/admin');
  };

  const onSumbit = async ({ name2, phoneNumber2, email2, ...data }) => {
    const ids = selectPaymentOptions.map((item) => item.value);
    const currentCarrierInfo = {
      ...currentCarrier,
      ...data,
      name2: name2 || null,
      phoneNumber2: phoneNumber2 || null,
      email2: email2 || null,
      paymentOptionIds: ids,
    };
    if (isChange) {
      const response = await changeCarrierAction(id, currentCarrierInfo);
      if (response) {
        alert('Changes were successful.');
        history.push('/admin');
      }
    } else {
      const response = await addCarrierAction(currentCarrierInfo);
      if (response) {
        setcurrentCarrier(response);
        alert(
          'Save base info successful. Broker Credential and signed document tabs available for filling'
        );
      }
    }
  };

  const handleClickRemove = async () => {
    const conf = window.confirm(`Are you sure?`);
    if (conf) {
      await deleteCarrierAction(id);
      setcurrentCarrier({});
      history.push('/admin');
    }
  };

  return (
    <form form onSubmit={handleSubmit(onSumbit)} className="ibox-content">
      <div className="row">
        <div className="col-lg-7 col-sm-6">
          <h2>New Carrier</h2>
        </div>
        <div className="col-lg-5 col-sm-6">
          <div className="input-group pull-right">
            <Button className="m-1" variant="light" onClick={handleClickCancel}>
              Cancel
            </Button>

            <Button className="invoice-btn m-1" type="submit">
              <FontAwesomeIcon icon={faSave} /> Save Carrier
            </Button>
            {isChange ? (
              <Button
                className="invoice-btn-two m-1"
                onClick={handleClickRemove}
              >
                {' '}
                <FontAwesomeIcon icon={faTrash} /> Delete Carrier
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row">
        <hr />

        <Tabs defaultActiveKey="carrierInfo" className="mb-3">
          <Tab eventKey="carrierInfo" title="Carrier info">
            <div>
              <div style={{ display: 'flex' }}>
                <div
                  className="p-2"
                  style={{ flexDirection: 'column', flexGrow: '1' }}
                >
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>
                        Business name <span className="error-simbol" />
                      </b>
                    </Form.Label>
                    <Form.Control
                      {...register('businessName', {
                        required: 'This is a required field',
                        minLength: 2,
                        maxLength: 50,
                      })}
                      type="text"
                      placeholder="Business name"
                    />
                    {errors.businessName && (
                      <p className="error">{errors.businessName.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>
                        MC/DOT <span className="error-simbol" />
                      </b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register('mcDot', {
                        required: 'This is a required field',
                      })}
                      placeholder="MC/DOT"
                    />
                    {errors.mcDot && (
                      <p className="error">{errors.mcDot.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>
                        Physical address <span className="error-simbol" />
                      </b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register('physicalAddress', {
                        required: 'This is a required field',
                        minLength: 5,
                        maxLength: 100,
                      })}
                      placeholder="Physical address"
                    />
                    {errors.physicalAddress && (
                      <p className="error">{errors.physicalAddress.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>
                        Mailing address <span className="error-simbol" />
                      </b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register('mailingAddress', {
                        required: 'This is a required field',
                        minLength: 5,
                        maxLength: 100,
                      })}
                      placeholder="Mailing address"
                    />
                    {errors.mailingAddress && (
                      <p className="error">{errors.mailingAddress.message}</p>
                    )}
                  </Form.Group>
                </div>

                <div
                  className="p-2"
                  style={{ flexDirection: 'column', flexGrow: '1' }}
                >
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>
                        Owner <span className="error-simbol" />
                      </b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register('owner', {
                        required: 'This is a required field',
                        minLength: 2,
                        maxLength: 50,
                      })}
                      placeholder="Owner"
                    />
                    {errors.owner && (
                      <p className="error">{errors.owner.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>
                        Е-mail <span className="error-simbol" />
                      </b>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      {...register('email', {
                        required: 'This is a required field',
                        minLength: 5,
                        maxLength: 50,
                      })}
                      placeholder="Enter email"
                    />
                    {errors.email && (
                      <p className="error">{errors.email.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      <b>
                        Phone Number <span className="error-simbol" />
                      </b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register('phoneNumber', {
                        required: 'This is a required field',
                        minLength: 6,
                        maxLength: 50,
                      })}
                      placeholder="Number"
                    />
                    {errors.phoneNumber && (
                      <p className="error">{errors.phoneNumber.message}</p>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  className="p-2"
                  style={{ flexDirection: 'column', flexGrow: '1' }}
                >
                  <Card.Title>Authorized contact person 1</Card.Title>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>
                        Name
                        <span className="error-simbol" />
                      </b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register('name1', {
                        required: 'This is a required field',
                        minLength: 2,
                        maxLength: 50,
                      })}
                      placeholder="Enter Name"
                    />
                    {errors.name1 && (
                      <p className="error">{errors.name1.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>
                        Phone number
                        <span className="error-simbol" />
                      </b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register('phoneNumber1', {
                        required: 'This is a required field',
                        minLength: 2,
                        maxLength: 50,
                      })}
                      placeholder="Enter number"
                    />
                    {errors.phoneNumber1 && (
                      <p className="error">{errors.phoneNumber1.message}</p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>
                        Email address
                        <span className="error-simbol" />
                      </b>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      {...register('email1', {
                        required: 'This is a required field',
                        minLength: 2,
                        maxLength: 50,
                      })}
                      placeholder="Enter email"
                    />
                    {errors.email1 && (
                      <p className="error">{errors.email1.message}</p>
                    )}
                  </Form.Group>
                </div>

                <div
                  className="p-2"
                  style={{ flexDirection: 'column', flexGrow: '1' }}
                >
                  <Card.Title>Authorized contact person 2</Card.Title>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Name</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register('name2')}
                      placeholder="Enter Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Phone number</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      {...register('phoneNumber2')}
                      placeholder="Enter number"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      <b>Email address</b>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      {...register('email2')}
                      placeholder="Enter email"
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="accountDetails" title="Account details">
            <div style={{ display: 'flex', minHeight: '40vh' }}>
              <div
                className="p-2"
                style={{ flexDirection: 'column', flexGrow: '1' }}
              >
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>
                      Bank name
                      <span className="error-simbol" />
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register('bankName', {
                      required: 'This is a required field',
                      minLength: 2,
                      maxLength: 50,
                    })}
                    placeholder="Enter Name"
                  />
                  {errors.bankName && (
                    <p className="error">{errors.bankName.message}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>
                      Bank address
                      <span className="error-simbol" />
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register('bankAddress', {
                      required: 'This is a required field',
                      minLength: 2,
                      maxLength: 100,
                    })}
                    placeholder="Enter Adress"
                  />
                  {errors.bankAddress && (
                    <p className="error">{errors.bankAddress.message}</p>
                  )}
                </Form.Group>
              </div>
              <div
                className="p-2"
                style={{ flexDirection: 'column', flexGrow: '1' }}
              >
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>
                      Business routing number
                      <span className="error-simbol" />
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register('businessRoutingNumber', {
                      required: 'This is a required field',
                      minLength: 2,
                      maxLength: 50,
                    })}
                    placeholder="Enter routing number"
                  />
                  {errors.businessRoutingNumber && (
                    <p className="error">
                      {errors.businessRoutingNumber.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>
                      Business account number
                      <span className="error-simbol" />
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register('businessAccountNumber', {
                      required: 'This is a required field',
                      minLength: 2,
                      maxLength: 50,
                    })}
                    placeholder="Enter account number"
                  />
                  {errors.businessAccountNumber && (
                    <p className="error">
                      {errors.businessAccountNumber.message}
                    </p>
                  )}
                </Form.Group>
              </div>
            </div>
          </Tab>
          <Tab eventKey="Fees" title="Fees">
            <div style={{ display: 'flex', minHeight: '40vh' }}>
              <div
                className="p-2"
                style={{ flexDirection: 'column', flexGrow: '1' }}
              >
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>
                      Factoring Fee <span className="error-simbol" />
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register('factoringFee', {
                      required: 'This is a required field',
                      min: 0,
                    })}
                    placeholder="Factoring Fee"
                  />
                  {errors.factoringFee && (
                    <p className="error">{errors.factoringFee.message}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>
                      Invoice Processing Fee
                      <span className="error-simbol" />
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register('invoiceProcessingFee', {
                      required: 'This is a required field',
                      min: 0,
                    })}
                    placeholder="Invoice Processing Fee"
                  />
                  {errors.invoiceProcessingFee && (
                    <p className="error">
                      {errors.invoiceProcessingFee.message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>
                      Submit Fee
                      <span className="error-simbol" />
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register('submitFee', {
                      required: 'This is a required field',
                      min: 0,
                    })}
                    placeholder="Submit Fee"
                  />
                  {errors.submitFee && (
                    <p className="error">{errors.submitFee.message}</p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Payment Options</b>
                  </Form.Label>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadPaymentOptions}
                    defaultOptions
                    isMulti
                    onChange={(value) => setSelectPaymentOptions(value)}
                    value={selectPaymentOptions}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: '.25rem',
                      colors: {
                        ...theme.colors,
                        primary25: '#f3f3f3',
                        primary: '#396cae',
                      },
                    })}
                  />
                </Form.Group>
              </div>
              <div
                className="p-2"
                style={{ flexDirection: 'column', flexGrow: '1' }}
              ></div>
            </div>
          </Tab>
          <Tab
            eventKey="brokerCredentials"
            title={
              !isChange ? (
                <span>
                  <FontAwesomeIcon icon={faLock} /> {'Broker Credentials'}
                </span>
              ) : (
                'Broker Credentials'
              )
            }
            disabled={!isChange}
          >
            <div style={{ display: 'block' }}>
              {id ? <BrokerCredentialGrid carrierId={id} /> : null}
            </div>
          </Tab>
          <Tab
            eventKey="signeddocument"
            title={
              !isChange ? (
                <span>
                  <FontAwesomeIcon icon={faLock} /> {'Signed Document'}
                </span>
              ) : (
                'Signed Document'
              )
            }
            disabled={!isChange}
          >
            <div style={{ display: 'flex', minHeight: '40vh' }}>
              {id ? <CarrierSignedDocuments id={id} /> : null}
            </div>
          </Tab>
          <Tab eventKey="settings" title="Settings">
            <div style={{ display: 'flex', minHeight: '40vh' }}>
              <div
                className="p-2"
                style={{ flexDirection: 'column', flexGrow: '1' }}
              >
                {isChange ? (
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Login: </b> {currentCarrier.email || ''}
                    </Form.Label>
                  </Form.Group>
                ) : null}

                {isChange ? (
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Reset Password</b>
                    </Form.Label>
                    <Form.Control
                      {...register('password')}
                      placeholder="Reset Password"
                    />
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>
                        Reset Password
                        <span className="error-simbol" />
                      </b>
                    </Form.Label>
                    <Form.Control
                      {...register('password', {
                        required: 'This is a required field',
                        minLength: 5,
                        maxLength: 20,
                      })}
                      placeholder="Reset Password"
                    />
                    {errors.password && (
                      <p className="error">{errors.password.message}</p>
                    )}
                  </Form.Group>
                )}

                {/* <Form.Group className="mb-3">
                                    <Form.Label><b>Confirm Password</b></Form.Label>
                                    <Form.Control type="password" placeholder="Confirm Password" />
                                </Form.Group> */}
              </div>
              <div
                className="p-2"
                style={{ flexDirection: 'column', flexGrow: '1' }}
              ></div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </form>
  );
}

export default AdmCarrier;
