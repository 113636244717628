import { Form as FormikForm, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Form as RForm } from 'react-bootstrap-formik';
import { useQueryClient } from 'react-query';
import { chargeInvoice } from '../../../api/InvoicesAPI';
import ModalWithStatus from '../../../components/modals/ModalWithStatus';
import { ChargesField } from '../components/ChargesField';

export function InvoiceChargeModalContent({ invoiceId, handleClose }) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const formik = useFormik({
    initialValues: {},
    onSubmit(values) {
      return chargeInvoice(invoiceId, values).then(() => {
        queryClient.invalidateQueries(['invoices']);
        setOpen(true);
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <ModalWithStatus
        show={open}
        status="success"
        handleClose={handleClose}
        title="Charge has been successfully created "
      />
      <FormikForm>
        <Modal.Header
          closeButton
          style={{ background: '#396cae', color: '#fff' }}
        >
          <Modal.Title>
            <h3 className="p-0 m-0">Charge Detail</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-2">
            <ChargesField name="typeOfChargeId" />
          </Form.Group>

          <Form.Group className="mb-2">
            <RForm.Input name="amount" type="number" placeholder="Amount" />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button type="submit" className="invoice-btn">
            Submit
          </Button>
        </Modal.Footer>
      </FormikForm>
    </FormikProvider>
  );
}

export function InvoiceChargeModal({ show, invoiceId, handleClose }) {
  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <InvoiceChargeModalContent {...{ invoiceId, handleClose }} />
    </Modal>
  );
}
