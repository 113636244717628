import { $authHost } from '../api/config';
import {
  setFetchError,
  setInvoces,
  setIsFetching,
} from '../reducers/invoceReducer';

export const getInvocesActionwithout = async (statusId = null) => {
  const response = await $authHost.get(
    `/api/invoice${statusId ? `?statusId=${statusId}` : null}`
  );

  if (response.status === 200) {
    return response.data;
  } else return null;
};

export const getSelectedInvoiceAction = async (invoiceId) => {
  const url = `/api/invoice/${invoiceId}`.replace(/[\u200B-\u200D\uFEFF]/g, '');
  const response = await $authHost.get(url);
  if (response.status === 200) {
    return response.data;
  } else return null;
};

export const getInvocesAction = (tab, filter) => {
  return async (dispatch) => {
    try {
      let url = `/api/invoice`;
      if (tab) {
        url += `?statusGroup=${tab}`;
      }
      if (Object.entries(filter).length !== 0) {
        const searchStr = Object.keys(filter)
          .map((key) => {
            let fItem = filter[key];
            return [key, fItem].map(encodeURIComponent).join('=');
          })
          .join('&');
        url += `&${searchStr}`;
      }
      dispatch(setIsFetching(true));
      const response = await $authHost.get(url);
      dispatch(setInvoces(response.data));
    } catch (e) {
      dispatch(setFetchError(true));
      dispatch(setIsFetching(false));
      setTimeout(() => {
        dispatch(setFetchError(false));
      }, 2000);
    }
  };
};

export const setInvocesAction = async (payload) => {
  const response = await $authHost.post(
    `/api/invoice`,
    JSON.stringify(payload),
    {
      headers: {
        'Content-Type': ' application/json',
      },
    }
  );
  if (response.status === 200) {
    return response.data;
  }
};

export const changeInvocesAction = async (invoiceId, payload) => {
  if (payload.status === 'Rejected') {
    await changeInvoiceStatus({
      invoiceId,
      invoiceStatusId: 1, // created
    });
    payload.status = 'Created';
  }

  const response = await $authHost.put(
    `/api/invoice/${invoiceId}`,
    JSON.stringify(payload),
    {
      headers: {
        'Content-Type': ' application/json',
      },
    }
  );
  if (response.status === 200) {
    return response.data;
  }
};

export const deleteInvocesAction = async (invoiceId, payload) => {
  const response = await $authHost.delete(`/api/invoice/${invoiceId}`, {
    headers: {
      'Content-Type': ' application/json',
    },
  });
  if (response.status === 200) {
    return response.data;
  }
};

export const changeInvoiceStatus = async (payload) => {
  return $authHost.post('/api/flow/carrier/change_status', payload);
};
