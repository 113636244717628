import { faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteDocumentsAction,
  downLoadDocumentsAction,
} from '../../actions/signDocuments';
import fileIcon from '../../assets/file.png';

function SignedDocument(props) {
  const dispatch = useDispatch();
  const onlyView = props.onlyView || false;

  const handleRemove = (uuid) => {
    const conf = window.confirm(`Are you sure?`);
    if (conf) {
      dispatch(deleteDocumentsAction(props.carrierId, uuid));
    }
  };

  const handleDownload = (uuid) => {
    return downLoadDocumentsAction(props.carrierId, uuid);
  };

  return (
    <div
      className="card"
      style={{ marginTop: '1rem', padding: '2rem', fontSize: '1.125rem' }}
    >
      <div className="row">
        <div className="col-sm-1">
          <img src={fileIcon} style={{ width: '2.5rem' }} />
        </div>
        <div className="col-sm-6 mt-2">{props.document.uuid}</div>
        <div className="col-sm-3 mt-1">{props.document.type}</div>
        {!onlyView ? (
          <div className="col-sm-2 mt-1">
            <div className="grid-icon-rows">
              <button
                className="grid-btn"
                type="button"
                title="Download"
                onClick={() => handleDownload(props.document.uuid)}
              >
                <FontAwesomeIcon icon={faDownload} />
              </button>
              <button
                className="grid-btn grid-btn__right"
                type="button"
                title="Delete"
                onClick={() => handleRemove(props.document.uuid)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SignedDocument;
