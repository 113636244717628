import { $authHost } from '../api/config';

export const carrierOptions = async () => {
  const response = await $authHost.get(`/api/carrier`, {
    headers: {
      'Content-Type': ' application/json',
    },
  });

  if (response.status === 200) {
    return response.data;
  }
};

export const stateOptions = async () => {
  const response = await $authHost.get(`/api/directory/state`, {
    headers: {
      'Content-Type': ' application/json',
    },
  });

  if (response.status === 200) {
    return response.data;
  }
};

export const statePaymentOptions = async () => {
  const response = await $authHost.get(`/api/directory/payment_option`, {
    headers: {
      'Content-Type': ' application/json',
    },
  });

  if (response.status === 200) {
    return response.data;
  }
};

export const documentTypeOptions = async () => {
  const response = await $authHost.get(`/api/directory/signed_document_type`, {
    headers: {
      'Content-Type': ' application/json',
    },
  });

  if (response.status === 200) {
    return response.data;
  }
};

export const invoiceStatusOptions = async () => {
  const response = await $authHost.get(`/api/directory/invoice_status`);

  if (response.status === 200) {
    return response.data;
  }
};

export const equipmentTypeOptions = async () => {
  const response = await $authHost.get(`/api/directory/equipment_type`);

  if (response.status === 200) {
    return response.data;
  }
};
