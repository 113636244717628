import { $authHost } from '../api/config';

export const getBrokersAction = async (page) => {
  const response = await $authHost.get(`/api/broker`, {
    params: { page },
  });

  if (response.status === 200) {
    return response.data;
  } else return null;
};

export const addBrokerAction = async (payload) => {
  const response = await $authHost.post(
    `/api/broker`,
    JSON.stringify(payload),
    {
      headers: {
        'Content-Type': ' application/json',
      },
    }
  );

  if (response.status === 200) {
    return response.data;
  } else return null;
};

export const changeBrokerAction = async (id, payload) => {
  const response = await $authHost.put(
    `/api/broker/${id}`,
    JSON.stringify(payload),
    {
      headers: {
        'Content-Type': ' application/json',
      },
    }
  );

  if (response.status === 200) {
    return response.data;
  } else return null;
};

export const deleteBrokerAction = async (id) => {
  const response = await $authHost.delete(`/api/broker/${id}`);

  if (response.status === 200) {
    return response.data;
  } else return null;
};

export const getSelectedBrokerAction = async (id) => {
  const response = await $authHost.get(`/api/broker/${id}`);

  if (response.status === 200) {
    return response.data;
  } else return null;
};
