import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import {
  brokerPaid,
  changeInvoiceStatus,
  INVOICE_STATUS,
} from '../../../api/AdminInvoicesAPI';
import { useInvoice } from '../../../api/InvoicesAPI';
import ModalChangeStatusInvoice from '../components/ModalChangeStatusInvoice';

const schema = yup.object({
  brokerPaidAmount: yup.number(),
  invoiceStatusId: yup.number().required(),
  comment: yup.string().nullable(),
});

export function ChangeStatusInvoiceForm({
  statusId,
  invoiceId,
  handleClose,
  onSuccess,
}) {
  const { data: invoice } = useInvoice(invoiceId);
  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues: {
      invoiceStatusId: 8,
      comment: null,
      brokerPaidAmount: 0,
    },
    enableReinitialize: true,
    validationSchema: schema,
    async onSubmit(values) {
      const { invoiceStatusId, comment, brokerPaidAmount } = values;

      await changeInvoiceStatus({
        comment,
        invoiceId: invoice.id,
        invoiceStatusId: Number(invoiceStatusId),
      });

      if (
        invoiceStatusId === INVOICE_STATUS.PAID ||
        invoiceStatusId === INVOICE_STATUS.SHORT_PAY ||
        invoiceStatusId === INVOICE_STATUS.OVER_PAY
      ) {
        await brokerPaid(invoice.id, Number(brokerPaidAmount));
      }

      queryClient.invalidateQueries(['invoices']);
      onSuccess(values);
    },
  });

  const {
    setFieldValue,
    values: { invoiceStatusId },
  } = formik;

  useEffect(() => {
    if (invoiceStatusId === INVOICE_STATUS.PAID && invoice?.balance) {
      setFieldValue('brokerPaidAmount', invoice.balance);
    }
  }, [setFieldValue, invoice?.balance, invoiceStatusId]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <ModalChangeStatusInvoice
          statusId={statusId}
          handleClose={handleClose}
        />
      </Form>
    </FormikProvider>
  );
}

export function ChangeStatusInvoiceContainer({
  show,
  statusId,
  invoiceId,
  handleClose,
  onSuccess,
}) {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <ChangeStatusInvoiceForm
        {...{ show, statusId, invoiceId, handleClose, onSuccess }}
      />
    </Modal>
  );
}
