import jwt_decode from 'jwt-decode';

export function getUser() {
  const token = sessionStorage.getItem('token');
  return jwt_decode(token);
}

export function isAdmin() {
  return getUser().role === 'ROLE_ADMIN';
}
