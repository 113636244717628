import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { stateOptions } from '../actions/directory';
import {
  addDriverAction,
  changeDriverAction,
  deleteDriverAction,
  getSelectedDriverAction,
} from '../actions/drivers';
import { logout } from '../reducers/currentReducer';

const loadStateOptions = async () => {
  const response = await stateOptions();
  const data = response.data;

  const optionList =
    data &&
    data.map((item) => ({
      value: item.abbreviation,
      label: `${item.state} (${item.abbreviation})`,
    }));
  return optionList;
};

function Driver({ match }) {
  const { id } = match.params;
  const carrier = useSelector((state) => state.carrier.currentCarrier);
  const carrierID = carrier.id;
  const dispatch = useDispatch();
  const isChange = id > -1;
  const [changeDriver, setchangeDriver] = useState({});
  const [selectState, setSelectState] = useState(null);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      const stateOptions = (await loadStateOptions()) || null;

      if (isChange) {
        const currentDriver = await getSelectedDriverAction(carrierID, id);

        if (currentDriver.status) dispatch(logout());
        else {
          const [selsectedStateOption] = stateOptions.filter(
            (item) => item.value === currentDriver.state
          );
          setSelectState(selsectedStateOption);
          setchangeDriver(currentDriver);
          reset(currentDriver);
        }
      }
    };
    fetchData();
  }, [reset]);

  const handleClickSave = async () => {
    console.table(changeDriver);
    if (isChange) {
      const response = await changeDriverAction(carrierID, id, changeDriver);
      if (response) {
        alert('Changes were successful');
        history.push('/manage-drivers');
      } else alert('An error occurred while saving data');
    } else {
      const response = await addDriverAction(carrierID, changeDriver);
      if (response) {
        alert('Save successful');
        history.push('/manage-drivers');
      } else alert('An error occurred while saving data');
    }
  };

  const onSumbit = async (data) => {
    const changeDriverInfo = {
      ...changeDriver,
      ...data,
      state: changeDriver.state,
    };
    console.table(changeDriverInfo);
    if (isChange) {
      const response = await changeDriverAction(
        carrierID,
        id,
        changeDriverInfo
      );
      if (response) {
        alert('Changes were successful');
        history.push('/manage-drivers');
      } else alert('An error occurred while saving data');
    } else {
      const response = await addDriverAction(carrierID, changeDriverInfo);
      if (response) {
        alert('Save successful');
        history.push('/manage-drivers');
      } else alert('An error occurred while saving data');
    }
  };

  const handleClickCancel = async () => {
    setchangeDriver({});
    history.push('/manage-drivers');
  };

  const handleClickRemove = async () => {
    const conf = window.confirm(`Are you sure?`);
    if (conf) {
      //TO DO:
      await deleteDriverAction(carrierID, id);
      setchangeDriver({});
      history.push('/manage-drivers');
    }
  };

  const handleChangeOptionState = async (newValue) => {
    setSelectState(newValue);
    setchangeDriver({ ...changeDriver, state: newValue.value });
  };

  return (
    <form className="ibox-content" onSubmit={handleSubmit(onSumbit)}>
      <div className="row">
        <div className="col-lg-8 col-md-6 col-sm-12">
          <h2>New Driver</h2>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="input-group pull-right">
            <Button className="m-1" variant="light" onClick={handleClickCancel}>
              Cancel
            </Button>
            <Button className="invoice-btn m-1" type="submit">
              <FontAwesomeIcon icon={faSave} /> Save Driver
            </Button>
            {isChange ? (
              <Button
                className="invoice-btn-two m-1"
                onClick={handleClickRemove}
              >
                {' '}
                <FontAwesomeIcon icon={faTrash} /> Delete Driver
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row">
        <hr />

        <div style={{ display: 'flex' }}>
          <div
            className="p-2"
            style={{ flexDirection: 'column', flexGrow: '1' }}
          >
            <Form.Group className="mb-3">
              <Form.Label>
                <b>
                  Name <span className="error-simbol" />
                </b>
              </Form.Label>
              <Form.Control
                type="text"
                {...register('name', {
                  required: 'This is a required',
                  minLength: { value: 0, message: ' length is 0' },
                  maxLength: 50,
                })}
                placeholder="Enter name"
              />
              {errors.name && <p className="error">{errors.name.message}</p>}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <b>Е-mail</b>
              </Form.Label>
              <Form.Control
                type="text"
                {...register('email')}
                placeholder="Enter email"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <b>
                  Phone number <span className="error-simbol" />
                </b>
              </Form.Label>
              <Form.Control
                type="text"
                {...register('phoneNumber', {
                  required: 'This is a required',
                  minLength: { value: 0, message: ' length is 0' },
                  maxLength: 50,
                })}
                placeholder="Enter number"
              />
              {errors.phoneNumber && (
                <p className="error">{errors.phoneNumber.message}</p>
              )}
            </Form.Group>
          </div>
          <div
            className="p-2"
            style={{ flexDirection: 'column', flexGrow: '1' }}
          >
            <Form.Group className="mb-3">
              <Form.Label>
                <b>
                  Address <span className="error-simbol" />
                </b>
              </Form.Label>
              <Form.Control
                type="text"
                {...register('address', {
                  required: 'This is a required',
                  minLength: { value: 0, message: ' length is 0' },
                  maxLength: 100,
                })}
                placeholder="Address"
              />
              {errors.address && (
                <p className="error">{errors.address.message}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <b>
                  City
                  <span className="error-simbol" />
                </b>
              </Form.Label>
              <Form.Control
                type="text"
                {...register('city', {
                  required: 'This is a required',
                  minLength: { value: 0, message: ' length is 0' },
                  maxLength: 100,
                })}
                placeholder="City"
              />
              {errors.city && <p className="error">{errors.city.message}</p>}
            </Form.Group>
            {/* <Form.Group className="mb-3">
                            <Form.Label><b>State</b></Form.Label>
                            <Form.Control type="text" value={changeDriver.state || ''} onChange={(e) => setchangeDriver({ ...changeDriver, state: e.target.value })} placeholder="State" />
                        </Form.Group> */}

            <Form.Group className="mb-3">
              <Form.Label>
                <b>
                  State <span className="error-simbol" />
                </b>
              </Form.Label>
              <AsyncSelect
                cacheOptions
                loadOptions={loadStateOptions}
                defaultOptions
                onChange={(value) => handleChangeOptionState(value)}
                value={selectState}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: '.25rem',
                  colors: {
                    ...theme.colors,
                    primary25: '#f3f3f3',
                    primary: '#396cae',
                  },
                })}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                <b>
                  Zip <span className="error-simbol" />
                </b>
              </Form.Label>
              <Form.Control
                type="text"
                {...register('zip', {
                  required: 'This is a required',
                  minLength: 5,
                  maxLength: 20,
                })}
                placeholder="size must be between 5 and 20."
              />
              {errors.zip && <p className="error">{errors.zip.message}</p>}
            </Form.Group>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Driver;
