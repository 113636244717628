import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentsAction } from '../../actions/signDocuments';
import SignedDocument from './SignedDocument';

function SignedDocuments(props) {
  const dispatch = useDispatch();
  const listDocuments = useSelector((state) => state.carrierDocs.items);

  useEffect(() => {
    dispatch(getDocumentsAction(props.id));
  }, [dispatch]);

  return (
    <section className="container mt-5">
      <div className="row">
        <div className="col-sm-6">
          <h3>List Files</h3>
        </div>
        <div className="col-sm-6">
          <div className="input-group pull-right"></div>
        </div>
      </div>
      <hr />
      <div className="row" style={{ paddingLeft: '2rem', fontWeight: '600' }}>
        <div className="col-sm-1"></div>
        <div className="col-sm-6">File Caption</div>
        <div className="col-sm-3">Type</div>
        <div className="col-sm-2">Action</div>
      </div>

      {listDocuments &&
        listDocuments.map((item) => (
          <SignedDocument
            key={item.uuid}
            carrierId={props.id}
            document={item}
          />
        ))}
    </section>
  );
}

export default SignedDocuments;
