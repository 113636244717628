import { useFormikContext } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { Form as Formik } from 'react-bootstrap-formik';
import { INVOICE_STATUS } from '../../../api/AdminInvoicesAPI';

function ModalChangeStatusInvoice({ statusId, handleClose }) {
  const {
    values: { invoiceStatusId },
  } = useFormikContext();
  return (
    <>
      <Modal.Header
        closeButton
        style={{ background: '#396cae', color: '#fff' }}
      >
        <Modal.Title>
          <h3 className="p-0 m-0">Change Status</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <Formik.Group name="invoiceStatusId">
            {statusId === INVOICE_STATUS.SUBMITTED ? (
              <>
                <div className="mb-2">
                  <Formik.Radio
                    label="Delayed advance"
                    name={INVOICE_STATUS.DELAYED_ADVANCE}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>
                <div className="mb-2">
                  <Formik.Radio
                    label="Rejected"
                    name={INVOICE_STATUS.REJECTED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>
              </>
            ) : statusId === INVOICE_STATUS.REVIEWED ? (
              <>
                <div className="mb-2">
                  <Formik.Radio
                    label="Verified"
                    name={INVOICE_STATUS.VERIFIED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>
                <div className="mb-2">
                  <Formik.Radio
                    label="Requires Attention"
                    name={INVOICE_STATUS.REQUIRES_ATTENTION}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>

                {invoiceStatusId === INVOICE_STATUS.REQUIRES_ATTENTION && (
                  <div className="mb-2">
                    <Formik.Textarea
                      name="comment"
                      required={true}
                      placeholder="Enter your comments"
                    />
                  </div>
                )}

                <div className="mb-2">
                  <Formik.Radio
                    label="Delayed advance"
                    name={INVOICE_STATUS.DELAYED_ADVANCE}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>

                <div className="mb-2">
                  <Formik.Radio
                    label="Rejected"
                    name={INVOICE_STATUS.REJECTED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>
              </>
            ) : statusId === INVOICE_STATUS.REQUIRES_ATTENTION ? (
              <>
                <div className="mb-2">
                  <Formik.Radio
                    label="Verified"
                    name={INVOICE_STATUS.VERIFIED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>
                <div className="mb-2">
                  <Formik.Radio
                    label="Approved"
                    name={INVOICE_STATUS.APPROVED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>

                <div className="mb-2">
                  <Formik.Radio
                    label="Delayed advance"
                    name={INVOICE_STATUS.DELAYED_ADVANCE}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>

                <div className="mb-2">
                  <Formik.Radio
                    label="Rejected"
                    name={INVOICE_STATUS.REJECTED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>
              </>
            ) : statusId === INVOICE_STATUS.DELAYED_ADVANCE ? (
              <>
                <div className="mb-2">
                  <Formik.Radio
                    label="Verified"
                    name={INVOICE_STATUS.VERIFIED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>
                <div className="mb-2">
                  <Formik.Radio
                    label="Approved"
                    name={INVOICE_STATUS.APPROVED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>

                <div className="mb-2">
                  <Formik.Radio
                    label="Requires Attention"
                    name={INVOICE_STATUS.REQUIRES_ATTENTION}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>

                {invoiceStatusId === INVOICE_STATUS.REQUIRES_ATTENTION && (
                  <div className="mb-2">
                    <Formik.Textarea
                      name="comment"
                      required={true}
                      placeholder="Enter your comments"
                    />
                  </div>
                )}

                <div className="mb-2">
                  <Formik.Radio
                    label="Rejected"
                    name={INVOICE_STATUS.REJECTED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>
              </>
            ) : statusId === INVOICE_STATUS.VERIFIED ? (
              <>
                <div className="mb-2">
                  <Formik.Radio
                    label="Approved"
                    name={INVOICE_STATUS.APPROVED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>

                <div className="mb-2">
                  <Formik.Radio
                    label="Delayed advance"
                    name={INVOICE_STATUS.DELAYED_ADVANCE}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>

                <div className="mb-2">
                  <Formik.Radio
                    label="Rejected"
                    name={INVOICE_STATUS.REJECTED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>
              </>
            ) : statusId === INVOICE_STATUS.APPROVED ||
              statusId === INVOICE_STATUS.INVOICED ||
              statusId === INVOICE_STATUS.PAID ||
              statusId === INVOICE_STATUS.SHORT_PAY ||
              statusId === INVOICE_STATUS.OVER_PAY ||
              statusId === INVOICE_STATUS.CHARGEBACK ? (
              <>
                {statusId !== INVOICE_STATUS.INVOICED && (
                  <div className="mb-2">
                    <Formik.Radio
                      label="Invoiced"
                      name={INVOICE_STATUS.INVOICED}
                      className="m-2"
                      style={{ fontSize: '18px' }}
                    />
                  </div>
                )}

                <div className="mb-2">
                  <Formik.Radio
                    label="Checked mailed"
                    name={INVOICE_STATUS.CHECK_MAILED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>

                {statusId !== INVOICE_STATUS.PAID && (
                  <div className="mb-2">
                    <Formik.Radio
                      label="Paid"
                      name={INVOICE_STATUS.PAID}
                      className="m-2"
                      style={{ fontSize: '18px' }}
                    />
                  </div>
                )}

                {statusId !== INVOICE_STATUS.SHORT_PAY && (
                  <div className="mb-2">
                    <Formik.Radio
                      label="Short pay"
                      name={INVOICE_STATUS.SHORT_PAY}
                      className="m-2"
                      style={{ fontSize: '18px' }}
                    />
                  </div>
                )}

                {statusId !== INVOICE_STATUS.OVER_PAY && (
                  <div className="mb-2">
                    <Formik.Radio
                      label="Over pay"
                      name={INVOICE_STATUS.OVER_PAY}
                      className="m-2"
                      style={{ fontSize: '18px' }}
                    />
                  </div>
                )}

                {statusId !== INVOICE_STATUS.CHARGEBACK && (
                  <div className="mb-2">
                    <Formik.Radio
                      label="Chargeback"
                      name={INVOICE_STATUS.CHARGEBACK}
                      className="m-2"
                      style={{ fontSize: '18px' }}
                    />
                  </div>
                )}

                <hr />

                <div className="mb-2">
                  <Formik.Radio
                    label="Delayed advance"
                    name={INVOICE_STATUS.DELAYED_ADVANCE}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>

                <div className="mb-2">
                  <Formik.Radio
                    label="Rejected"
                    name={INVOICE_STATUS.REJECTED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>
              </>
            ) : statusId === INVOICE_STATUS.REJECTED ? (
              <>
                <div className="mb-2">
                  <Formik.Radio
                    label="Verified"
                    name={INVOICE_STATUS.VERIFIED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>

                <div className="mb-2">
                  <Formik.Radio
                    label="Approved"
                    name={INVOICE_STATUS.APPROVED}
                    className="m-2"
                    style={{ fontSize: '18px' }}
                  />
                </div>
              </>
            ) : null}

            {(invoiceStatusId === INVOICE_STATUS.PAID ||
              invoiceStatusId === INVOICE_STATUS.SHORT_PAY ||
              invoiceStatusId === INVOICE_STATUS.OVER_PAY) && (
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>Broker Paid Amount</b>
                </Form.Label>
                <Formik.Input
                  type="text"
                  name="brokerPaidAmount"
                  placeholder="Enter the value"
                />
              </Form.Group>
            )}
          </Formik.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button type="submit" className="invoice-btn">
          Ok
        </Button>
      </Modal.Footer>
    </>
  );
}

export default ModalChangeStatusInvoice;
