import {
  faFileAlt,
  faList,
  faUserCog,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { INVOICE_STATUS } from '../../api/AdminInvoicesAPI';
import logo from '../../assets/adm-log.png';
import './AdmSidebar.scss';

function AdmSedebar(props) {
  const handleClick = (e) => {
    // TO DO:
    let sidebarMenuItems = document.getElementsByClassName('as-menu_link');
    for (var item of sidebarMenuItems) {
      if (item.classList.contains('as-active-link'))
        item.classList.remove('as-active-link');
    }
    e.currentTarget.classList.add('as-active-link');
  };

  return (
    <div className="adm-sidebar">
      <div className="adm-sidebar__title">
        <div className="adm-sidebar__img">
          <img className="img-fluid" src={logo} alt="logo" />
        </div>
        <h5 className="mt-3">Admin panel Web Portal</h5>
      </div>

      <div className="adm-sidebar__menu">
        <Link to="/admin">
          <div
            className="adm-sidebar__link as-menu_link as-active-link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faUserCog} className="mr-2" /> Carriers
            </span>
          </div>
        </Link>

        <Link to={`/manage-invoices?statusId=${INVOICE_STATUS.SUBMITTED}`}>
          <div
            className="adm-sidebar__link as-menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Invoice
              Check
            </span>
          </div>
        </Link>

        <Link to={`/manage-invoices?statusId=${INVOICE_STATUS.REVIEWED}`}>
          <div
            className="adm-sidebar__link as-menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Reviewed
              Invoices
            </span>
          </div>
        </Link>
        <Link
          to={`/manage-invoices?statusId=${INVOICE_STATUS.REQUIRES_ATTENTION}`}
        >
          <div
            className="adm-sidebar__link as-menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Invoice !
            </span>
          </div>
        </Link>

        <Link
          to={`/manage-invoices?statusId=${INVOICE_STATUS.DELAYED_ADVANCE}`}
        >
          <div
            className="adm-sidebar__link as-menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Invoices in
              Dispute
            </span>
          </div>
        </Link>

        <Link to={`/manage-invoices?statusId=${INVOICE_STATUS.VERIFIED}`}>
          <div
            className="adm-sidebar__link as-menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Verified
              Invoices
            </span>
          </div>
        </Link>

        <Link to={`/manage-invoices?statusId=${INVOICE_STATUS.APPROVED}`}>
          <div
            className="adm-sidebar__link as-menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Processed
              Invoices
            </span>
          </div>
        </Link>

        <Link to={`/manage-invoices?statusId=${INVOICE_STATUS.PAID}`}>
          <div
            className="adm-sidebar__link as-menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Paid
              Invoices
            </span>
          </div>
        </Link>

        <Link to={`/manage-invoices?statusId=${INVOICE_STATUS.SHORT_PAY}`}>
          <div
            className="adm-sidebar__link as-menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Short Pay
              Invoices
            </span>
          </div>
        </Link>

        <Link to={`/manage-invoices?statusId=${INVOICE_STATUS.OVER_PAY}`}>
          <div
            className="adm-sidebar__link as-menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Over pay
              Invoices
            </span>
          </div>
        </Link>

        <Link to={`/manage-invoices?statusId=${INVOICE_STATUS.CHARGEBACK}`}>
          <div
            className="adm-sidebar__link as-menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Chargeback
              Invoices
            </span>
          </div>
        </Link>

        <Link to={`/manage-invoices?statusId=${INVOICE_STATUS.INVOICED}`}>
          <div
            className="adm-sidebar__link as-menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Invoiced
              Invoices
            </span>
          </div>
        </Link>

        <Link to={`/manage-invoices?statusId=${INVOICE_STATUS.REJECTED}`}>
          <div
            className="adm-sidebar__link as-menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faFileAlt} className="mr-2" /> Rejected
              Invoices
            </span>
          </div>
        </Link>

        <Link to="/manage-brokers">
          <div
            className="adm-sidebar__link as-menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faList} className="mr-2" /> Brokers
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default AdmSedebar;
