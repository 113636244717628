import jwt_decode from 'jwt-decode';
import { $authHost } from '../api/config';

export const addFileInvoice = async (type, file) => {
  const token = sessionStorage.getItem('token');
  const jwtInfo = jwt_decode(token);
  const formData = new FormData();
  formData.append('file', file);

  const response = await $authHost.post(
    `/api/carrier/${jwtInfo.id}/file?fileType=${type}`,
    formData
  );
  if (response.status === 200) {
    return response.data;
  }
};

export const downLoadFileInvoice = async (fileId) => {
  const token = sessionStorage.getItem('token');
  const jwtInfo = jwt_decode(token);
  const url = `/api​/carrier​/${jwtInfo.id}​/file​/${fileId}`.replace(
    /[\u200B-\u200D\uFEFF]/g,
    ''
  );
  const response = await $authHost.get(url, {
    responseType: 'blob',
    headers: {
      Accept: 'application/octet-stream',
    },
  });
  const data = response.data;
  if (!(data instanceof Blob)) return;

  const filename = 'File';
  const blob = new Blob([data], { type: data.type });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${filename}-${+new Date()}.pdf`;
  link.click();
};

export const deleteFileInvoice = async (fileId) => {
  const token = sessionStorage.getItem('token');
  const jwtInfo = jwt_decode(token);
  const response = await $authHost.delete(
    `/api/carrier/${jwtInfo.id}/file/${fileId}`
  );
  if (response.status === 200) {
    return response.data;
  }
};
