import { faList, faTruck, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import './Sidebar.scss';

const Sidebar = (props) => {
  const { currentCarrier } = useSelector((state) => state.carrier);

  const handleClick = (e) => {
    // TO DO:
    let sidebarMenuItems = document.getElementsByClassName('menu_link');
    for (var item of sidebarMenuItems) {
      if (item.classList.contains('active-link'))
        item.classList.remove('active-link');
    }
    e.currentTarget.classList.add('active-link');
  };

  return (
    <div id="sidebar">
      <div className="sidebar__title">
        <div className="sidebar__img">
          <img src={logo} alt="logo" />
        </div>
        <h5 className="mt-3">{currentCarrier?.businessName}</h5>
      </div>

      <div className="sidebar__menu">
        <Link to="/invoices">
          <div
            className="sidebar__link menu_link active-link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faList} className="mr-2" /> Invoices
            </span>
          </div>
        </Link>

        <Link to="/manage-drivers">
          <div
            className="sidebar__link menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faTruck} className="mr-2" /> Manage Drivers{' '}
            </span>
          </div>
        </Link>
        <Link to="/current-carrier">
          <div
            className="sidebar__link menu_link"
            onClick={(e) => handleClick(e)}
          >
            <span>
              <FontAwesomeIcon icon={faUserCog} className="mr-2" /> Carrier{' '}
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
