import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { check } from '../actions/carrier';
import { isAdmin } from '../actions/user';
import BuildNewInvoices from '../components/buildNewInvoices/BuildNewInvoices';
import Auth from '../pages/Auth';
import Carrier from '../pages/Carrier';
import Driver from '../pages/Driver';
import Drivers from '../pages/Drivers';
import Invoices from '../pages/invoices/Invoices';
import InvoiceViewPage from '../pages/invoices/InvoiceViewPage';
import { AdminRoute } from './AdminRoute';
import Layout from './layout/Layout';

function RouteWithLayout({ layout, component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        React.createElement(
          layout,
          props,
          React.createElement(component, props)
        )
      }
    />
  );
}

function App() {
  const isAuth = useSelector((state) => state.carrier.isAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(check());
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener(
      'click',
      (event) => {
        // prevent clicks when user tries copy text
        if (getSelection().type === 'Range') {
          event.stopImmediatePropagation();
        }
      },
      { capture: true }
    );
  }, []);

  if (isAuth == null) {
    return (
      <div className="d-flex vh-100 justify-content-center align-items-center">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <BrowserRouter>
      {!isAuth ? (
        <Switch>
          <Route path="/login" component={Auth} />
          <Redirect to="/login" />
        </Switch>
      ) : isAdmin() ? (
        <AdminRoute />
      ) : (
        <Switch>
          <RouteWithLayout
            layout={Layout}
            path="/"
            component={Invoices}
            exact
          />
          <RouteWithLayout
            layout={Layout}
            path="/invoices/:id"
            component={InvoiceViewPage}
            exact
          />
          <RouteWithLayout
            layout={Layout}
            path="/build-invoices/:id?"
            component={BuildNewInvoices}
          />
          <RouteWithLayout
            layout={Layout}
            path="/current-carrier"
            component={Carrier}
          />
          <RouteWithLayout
            layout={Layout}
            path="/manage-drivers"
            component={Drivers}
          />
          <RouteWithLayout
            layout={Layout}
            path="/select-driver/:id?"
            component={Driver}
          />
          <Redirect to="/" />
        </Switch>
      )}
    </BrowserRouter>
  );
}

export default App;
