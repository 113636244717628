import { useState } from 'react';
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';
import './Layout.scss';

const Layout = (props) => {
  const [sidebarOpen, setsidebarOpen] = useState(false);
  const openSidebar = () => {
    setsidebarOpen(true);
  };
  const closeSidebar = () => {
    setsidebarOpen(false);
  };
  return (
    <div className="base-container">
      <div className="base-container__left">
        <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
      </div>
      <div className="base-container__right">
        <Navbar sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
        <main>{props.children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
