import {
  faCheck,
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

function ModalWithStatus({ show, handleClose, status, title, message }) {
  const setStatusIcon = (status) => {
    switch (status) {
      case 'error':
        return (
          <span className="status-error">
            <FontAwesomeIcon icon={faTimes} />
          </span>
        );
      case 'success':
        return (
          <span className="status-success">
            <FontAwesomeIcon icon={faCheck} />
          </span>
        );
      default:
        return (
          <span className="status-info">
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </span>
        );
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Body>
        <div className="status-modal">
          <div>
            {setStatusIcon(status)}
            <h2> {title}</h2>
            <p> {message}</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose();
          }}
        >
          {' '}
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalWithStatus;
