import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getDriversAction } from '../actions/drivers';
import MyAction from '../components/admcarriers/MyAction';

function AdmDrivers(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState(null);
  const carrier = useSelector((state) => state.carrier.currentCarrier);
  const { id } = carrier;
  const currentPage = useSelector((state) => state.driver.currentPage);
  const drivers = useSelector((state) => state.driver.items);

  useEffect(() => {
    dispatch(getDriversAction(id, currentPage));
  }, [currentPage]);

  return (
    <div>
      <div className="pl-3" style={{ margin: '0px 10px' }}>
        <div className="row">
          <div className="p-0 m-0">
            <MaterialTable
              title={<h2 style={{ margin: '1.5rem 0rem' }}>Drivers</h2>}
              columns={[
                { title: 'id', field: 'id', hidden: true },
                { title: 'Name', field: 'name', cellStyle: { width: '20%' } },
                {
                  title: 'Address',
                  field: 'address',
                  cellStyle: { width: '20%' },
                },
                {
                  title: 'Email',
                  field: 'email',
                  cellStyle: { width: '20%' },
                },
                {
                  title: 'Phone Number',
                  field: 'phoneNumber',
                  cellStyle: { width: '20%' },
                },
                {
                  title: 'State',
                  field: 'state',
                  cellStyle: { width: '10%' },
                },
                { title: 'Zip', field: 'zip', cellStyle: { width: '10%' } },
              ]}
              data={drivers}
              onRowClick={(evt, selectedRow) => {
                setSelectedRow(selectedRow.id);
                history.push(`/driver/${selectedRow.id}`);
              }}
              options={{
                selection: true,
                pageSize: 15,
              }}
              components={{
                Action: (props) => <MyAction {...props} />,
                Toolbar: (props) => (
                  <div style={{ color: '#676a6c' }}>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              actions={[
                {
                  tooltip: 'Remove All Selected Rows',
                  icon: 'delete',
                  onClick: (evt, data) => {
                    // const conf = window.confirm(`Are you sure?`);
                    // if (conf) {
                    //     //TO DO:
                    // }
                  },
                },
                {
                  icon: () => (
                    <Button
                      className="invoice-btn"
                      onClick={() => history.push('/driver')}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add New Driver
                    </Button>
                  ),
                  onClick: (event, rowData) => {
                    //TO DO:
                  },
                  isFreeAction: true,
                  tooltip: 'Add New Driver',
                },
              ]}
              localization={{
                toolbar: { searchPlaceholder: 'Search for an Driver' },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdmDrivers;
