import {
  faBars,
  faHome,
  faSignOutAlt,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { searchInvoice } from '../../api/InvoicesAPI';
import { logout } from '../../reducers/carrierReducer';
import './Navbar.scss';

const Navbar = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const carrier = useSelector((state) => state.carrier.currentCarrier);
  return (
    <nav className="navbar navbar-expand navbar-light bg-light justify-content-between">
      <div className="navbar-brand" style={{ display: 'flex' }}>
        <Button className="invoice-btn">
          <FontAwesomeIcon icon={faBars} />{' '}
        </Button>
        <div role="search" className="navbar-form-custom">
          <div className="form-group">
            <AsyncSelect
              id="header_invoice_search_txt_search"
              placeholder="Search for an invoice..."
              className="form-control txt_search"
              defaultOptions
              loadOptions={async (search) => {
                const response = await searchInvoice(search);
                return response.data.data.map((item) => {
                  return {
                    value: item.id,
                    label: `${item.reference} ${item.invoice} $ ${item.amount} ${item.carrierBusinessName}`,
                  };
                });
              }}
              styles={{
                container: (provided) => ({
                  ...provided,
                  zIndex: 100,
                }),
              }}
              onChange={({ value }) => {
                history.push(`/invoices/${value}`);
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: '.25rem',
                colors: {
                  ...theme.colors,
                  primary25: '#f3f3f3',
                  primary: '#396cae',
                },
              })}
            />
          </div>
        </div>
      </div>
      <div id="navbarText">
        <ul className="navbar-nav">
          <li className="nav-item">
            <span className="nav-link">Welcome to Invoices Web Portal.</span>
          </li>
          {carrier && carrier.role === 'ROLE_ADMIN' ? (
            <li className="nav-item">
              <Link className="nav-link navbar-link" to="/admin">
                <FontAwesomeIcon icon={faWrench} /> Admin panel
              </Link>
            </li>
          ) : null}
          <li className="nav-item">
            <Link className="nav-link navbar-link" to="/invoices">
              <FontAwesomeIcon icon={faHome} /> Home
            </Link>
          </li>
          <li className="nav-item">
            <button
              style={{ border: 'none', background: 'inherit' }}
              className="nav-link navbar-link"
              onClick={() => dispatch(logout())}
            >
              <FontAwesomeIcon icon={faSignOutAlt} /> Log Out
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
