import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cls from 'classnames';
import { formatDateTime } from '../../../utils/DateUtils';

function getActionType(action) {
  switch (action) {
    case 'Comment added':
      return 'commented';
    case 'Invoice Edited':
      return 'edited';
    case 'New File added':
      return 'file_added';
    case 'CREATED':
      return null;
    default:
      return 'status_change';
  }
}

function InvoiceActivityItem({ message, isCompleted, activity }) {
  return (
    <div className={cls('step', isCompleted && 'completed')}>
      <div className="v-stepper">
        <div className="circle">
          {isCompleted && <FontAwesomeIcon icon={faCheck} />}
        </div>
        <div className="line"></div>
      </div>

      <div
        className={cls(
          'content',
          'action',
          getActionType(activity.action) &&
            `action-${getActionType(activity.action)}`
        )}
      >
        <p>
          <b>{activity.author}</b>
        </p>
        <p className="m-0">
          <b>{activity.action}</b> on {formatDateTime(activity.date)}
        </p>

        {activity.description && (
          <b className={activity.action === 'CREATED' && 'first-message'}>
            {activity.description}
          </b>
        )}
      </div>
    </div>
  );
}

export default InvoiceActivityItem;
