import { $authHost } from '../api/config';

export const getBrokersAction = async (carrierId) => {
  const response = await $authHost.get(
    `/api/carrier/${carrierId}/broker_credential`
  );
  if (response.status === 200) {
    return response.data;
  }
};

export const addBrockerAction = async (carrierId, payload) => {
  const response = await $authHost.post(
    `/api/carrier/${carrierId}/broker_credential`,
    JSON.stringify(payload),
    {
      headers: {
        'Content-Type': ' application/json',
      },
    }
  );

  if (response.status === 200) {
    return response.data;
  }
};

export const changeBrockerAction = async (carrierId, brokerId, payload) => {
  const response = await $authHost.put(
    `/api/carrier/${carrierId}/broker_credential/${brokerId}`,
    JSON.stringify(payload),
    {
      headers: {
        'Content-Type': ' application/json',
      },
    }
  );

  if (response.status === 200) {
    return response.data;
  }
};

export const deleteBrockerAction = async (carrierId, brokerId, payload) => {
  const response = await $authHost.delete(
    `/api/carrier/${carrierId}/broker_credential/${brokerId}`,
    JSON.stringify(payload),
    {
      headers: {
        'Content-Type': ' application/json',
      },
    }
  );

  if (response.status === 200) {
    return response.data;
  }
};
