// const SET_CURRENT = "SET_CURRENT"
// const SET_ABOUT_MY = "SET_ABOUT_MY"
// const LOGOUT = "LOGOUT"

// const defaultState = {
//     body: {},
//     isAuth: false
// }

// export default function currentReducer(state = defaultState, action) {
//     switch (action.type) {
//         case SET_CURRENT:
//             return {
//                 ...state,
//                 body: action.payload,
//                 isAuth: true
//             }
//         case LOGOUT:
//             sessionStorage.removeItem('token')
//             return {
//                 ...state,
//                 body: action.payload,
//                 isAuth: false
//             }
//         default:
//             return state
//     }
// }

// export const setCurrent = user => ({ type: SET_CURRENT, payload: user })
// export const logout = () => ({ type: LOGOUT })
