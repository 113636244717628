import { Button } from 'react-bootstrap';
import { isAdmin } from '../../../actions/user';
import { InvoiceFileItem } from './InvoiceFileItem';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 5,
  overflow: 'auto',
};

function DocumentsTab({ invoice, onAddFile, onAddComment, onAddCharge }) {
  const files = [
    invoice.rate,
    invoice.bol,
    invoice.limper,
    invoice.scaleTicket,
    invoice.other,
    invoice.revisedRateCon,
    invoice.other2,
    invoice.other3,
  ].filter(Boolean);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <aside style={thumbsContainer}>
        {files.map((file) => (
          <InvoiceFileItem
            key={file.id}
            file={file}
            carrierID={invoice.carrier.id}
          />
        ))}
      </aside>

      <div>
        <Button
          onClick={onAddFile}
          variant="outline-secondary"
          className=" btn-default mt-1"
        >
          Add Additional File
        </Button>

        <Button
          onClick={onAddComment}
          variant="outline-secondary"
          className="btn-default mt-1"
        >
          Add comment
        </Button>

        {!isAdmin() && (
          <Button
            onClick={onAddCharge}
            variant="outline-secondary"
            className="btn-default mt-1"
          >
            Add Charges
          </Button>
        )}
      </div>
    </div>
  );
}

export default DocumentsTab;
