import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { stringifyDate } from '../../../utils/DateUtils';
import { DriverSelect } from './DriverSelect';
import { StatusSelect } from './StatusSelect';

const InvoiceFilterModal = ({ show, tab, handleClose, value, onSubmit }) => {
  const [filter, setFilter] = useState(value);

  useEffect(() => {
    setFilter(value);
  }, [value]);

  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit(filter);
        }}
      >
        <Modal.Header
          closeButton
          style={{ background: '#396cae', color: '#fff' }}
        >
          <Modal.Title>Filter Invoices</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={{ display: 'flex' }}>
            <div className="p-2" style={{ flex: '1 1 25%' }}>
              <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>
                  <b>By Invoice Number</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={filter.invoiceNumber}
                  onChange={(e) =>
                    setFilter({ ...filter, invoiceNumber: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>
                  <b>By Reference</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={filter.reference}
                  onChange={(e) =>
                    setFilter({ ...filter, reference: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>
                  <b>By Date</b>
                </Form.Label>
                <div style={{ display: 'inline-flex' }}>
                  <div className="m-1">
                    <DatePicker
                      className="form-control"
                      maxDate={filter.endDate && new Date(filter.endDate)}
                      selected={filter.beginDate && new Date(filter.beginDate)}
                      onChange={(value) =>
                        setFilter({
                          ...filter,
                          beginDate: stringifyDate(value),
                        })
                      }
                    />
                    <Form.Text className="text-muted">Begin Date</Form.Text>
                  </div>
                  <div className="m-1">
                    <DatePicker
                      className="form-control"
                      minDate={filter.beginDate && new Date(filter.beginDate)}
                      selected={filter.endDate && new Date(filter.endDate)}
                      onChange={(value) =>
                        setFilter({ ...filter, endDate: stringifyDate(value) })
                      }
                    />
                    <Form.Text className="text-muted">End Date</Form.Text>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="p-2" style={{ flex: '1 1 25%' }}>
              <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>
                  <b>By Driver</b>
                </Form.Label>
                <DriverSelect
                  value={filter.driverId}
                  onChange={(driver) =>
                    setFilter({ ...filter, driverId: driver.id })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>
                  <b>By Status</b>
                </Form.Label>
                <StatusSelect
                  statusGroup={tab}
                  value={filter.statusId}
                  onChange={(status) =>
                    setFilter({ ...filter, statusId: status.id })
                  }
                />
              </Form.Group>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              onSubmit({});
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="invoice-btn"
            disabled={Object.entries(filter).length === 0}
          >
            Apply
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default InvoiceFilterModal;
