import { useState } from 'react';
import { Badge, Button, Tab, Tabs } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { isAdmin } from '../../actions/user';
import { useInvoice } from '../../api/InvoicesAPI';
import { currencyMoneyFormat } from '../../utils/DataUtils';
import { formatDate } from '../../utils/DateUtils';
import DocumentsTab from './components/DocumentsTab';
import InvoiceActivityTab from './containers/InvoiceActivityTab';
import { InvoiceChargeModal } from './containers/InvoiceChargeModal';
import { InvoiceCommentModalContainer } from './containers/InvoiceCommentModalContainer';
import { InvoiceFileModalContainer } from './containers/InvoiceFileModal';
import { canEditInvoice } from './InvoiceHelpers';

function InvoiceViewPage() {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useInvoice(id);
  const [dialog, setDialog] = useState();

  if (!data) {
    return null;
  }

  return (
    <>
      <InvoiceCommentModalContainer
        invoiceId={id}
        show={dialog === 'comment'}
        handleClose={() => setDialog(undefined)}
      />

      <InvoiceChargeModal
        invoiceId={id}
        show={dialog === 'charge'}
        handleClose={() => setDialog(undefined)}
      />

      <InvoiceFileModalContainer
        invoiceId={id}
        show={dialog === 'file'}
        handleClose={() => setDialog(undefined)}
      />

      <div className="ibox-content">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>{data.broker.debtorCompanyName} </h2>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2>{currencyMoneyFormat(data.balance)}</h2>

            {canEditInvoice(data) && (
              <Button
                size="sm"
                variant="light"
                onClick={() => {
                  if (isAdmin()) {
                    history.push(`/manage-invoice/${id}/edit`);
                  } else {
                    history.push(`/build-invoices/${id}`);
                  }
                }}
              >
                Edit
              </Button>
            )}
          </div>
        </div>

        <table className="table-info-invoice">
          <tr>
            <td className="left">Status:</td>
            <td>
              <Badge bg="success">{data.status}</Badge>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="left">Invoice #:</td>
            <td>{data.invoice}</td>
            <td className="left">Purchased:</td>
            <td>{formatDate(data.createAt)}</td>
          </tr>
          <tr>
            <td className="left">Delivery Date:</td>
            <td>{formatDate(data.deliveryDate)}</td>
            <td className="left">Balance:</td>
            <td>${data.balance}</td>
          </tr>
          <tr>
            <td className="left">Delivery:</td>
            <td>{data.deliveryLocation}</td>
            <td></td>
            <td></td>
          </tr>
        </table>

        <Tabs defaultActiveKey="documents" className="mb-3">
          <Tab eventKey="documents" title="Documents">
            <DocumentsTab
              invoice={data}
              onAddCharge={() => setDialog('charge')}
              onAddFile={() => setDialog('file')}
              onAddComment={() => setDialog('comment')}
            />
          </Tab>

          <Tab eventKey="invoiceActivity" title="Invoice Activity">
            <InvoiceActivityTab
              invoice={data}
              onAddComment={() => setDialog('comment')}
            />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default InvoiceViewPage;
