import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const Carrier = () => {
  const carrier = useSelector((state) => state.carrier.currentCarrier);
  return (
    <div className="ibox-content">
      <div className="row">
        <div className="col-lg-9 col-md-6 col-sm-12">
          <h2>Carrier</h2>
        </div>
      </div>

      <hr />
      <div style={{ display: 'flex', fontSize: '14px' }}>
        <div
          className="container pt-2 pl-4"
          style={{ flexDirection: 'column', flexGrow: '1' }}
        >
          <Card.Title>Carrier info</Card.Title>
          <div className="p-2">
            <dl className="row mb-1">
              <dt className="col-sm-6">Business name</dt>
              <dd className="col-sm-6">{carrier.businessName}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">MC/DOT</dt>
              <dd className="col-sm-6">{carrier.mcDot}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">Physical address</dt>
              <dd className="col-sm-6">{carrier.physicalAddress}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">Mailing address</dt>
              <dd className="col-sm-6">{carrier.mailingAddress}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">Owner</dt>
              <dd className="col-sm-6">{carrier.owner}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">Е-mail</dt>
              <dd className="col-sm-6">{carrier.email}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">Number</dt>
              <dd className="col-sm-6">{carrier.phoneNumber}</dd>
            </dl>
          </div>
        </div>

        <div
          className="container p-2"
          style={{ flexDirection: 'column', flexGrow: '1' }}
        >
          <Card.Title>Authorized contact person 1</Card.Title>
          <div className="p-2">
            <dl className="row mb-1">
              <dt className="col-sm-6">Name</dt>
              <dd className="col-sm-6">{carrier.name1}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">Phone number</dt>
              <dd className="col-sm-6">{carrier.phoneNumber1}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">Email address</dt>
              <dd className="col-sm-6">{carrier.email1}</dd>
            </dl>
          </div>
          <Card.Title>Authorized contact person 2</Card.Title>
          <div className="p-2">
            <dl className="row mb-1">
              <dt className="col-sm-6">Name</dt>
              <dd className="col-sm-6">{carrier.name2}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">Phone number</dt>
              <dd className="col-sm-6">{carrier.phoneNumber2}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">Email address</dt>
              <dd className="col-sm-6">{carrier.email2}</dd>
            </dl>
          </div>
        </div>
        <div
          className="container p-2"
          style={{ flexDirection: 'column', flexGrow: '1' }}
        >
          <Card.Title>Account details</Card.Title>
          <div className="p-2">
            <dl className="row mb-1">
              <dt className="col-sm-6">Bank name</dt>
              <dd className="col-sm-5">{carrier.bankName}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">Bank address</dt>
              <dd className="col-sm-5">{carrier.bankAddress}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">Business routing number</dt>
              <dd className="col-sm-5">{carrier.businessRoutingNumber}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">Business account number</dt>
              <dd className="col-sm-5">{carrier.businessAccountNumber}</dd>
            </dl>
          </div>
          <Card.Title>Fees</Card.Title>
          <div className="p-2">
            <dl className="row mb-1">
              <dt className="col-sm-6">Factoring Fee</dt>
              <dd className="col-sm-6">{carrier.factoringFee}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">Invoice Processing Fee</dt>
              <dd className="col-sm-6">{carrier.invoiceProcessingFee}</dd>
            </dl>
            <dl className="row mb-1">
              <dt className="col-sm-6">Submit Fee</dt>
              <dd className="col-sm-6">{carrier.submitFee}</dd>
            </dl>
          </div>
        </div>
      </div>
      <div className="container pt-2 pl-4">
        <Card.Title>Payment Options</Card.Title>
        {carrier.paymentOptions.map((item) => (
          <ol>{item.name}</ol>
        ))}
      </div>
    </div>
  );
};

export default Carrier;
