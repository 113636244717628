import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AdmBroker from '../pages/AdmBroker';
import AdmBrokers from '../pages/AdmBrokers';
import AdmDriver from '../pages/AdmDriver';
import AdmDrivers from '../pages/AdmDrivers';
import AdminInvoicesPage from '../pages/invoices/AdminInvoicesPage';
import InvoiceViewPage from '../pages/invoices/InvoiceViewPage';
import Settings from '../pages/Settings';
import AdmCarrier from './admCarrier/AdmCarrier';
import AdmCarriers from './admcarriers/AdmCarriers';
import Admlayout from './admlayout/Admlayout';
import BuildNewInvoices from './buildNewInvoices/BuildNewInvoices';

export function AdminRoute() {
  return (
    <Admlayout>
      <Switch>
        <Route path="/admin" component={AdmCarriers} />
        <Route path="/carrier/:id?" component={AdmCarrier} />
        <Route path="/drivers" component={AdmDrivers} />
        <Route path="/driver/:id?" component={AdmDriver} />
        <Route path="/manage-invoices" component={AdminInvoicesPage} />
        <Route path="/manage-invoice/:id/edit" component={BuildNewInvoices} />
        <Route path="/manage-invoice/:id" component={InvoiceViewPage} />
        <Route path="/manage-brokers" component={AdmBrokers} />
        <Route path="/broker/:id?" component={AdmBroker} />
        <Route path="/settings" component={Settings} />
        <Route>
          <Redirect to="/admin" />
        </Route>
      </Switch>
    </Admlayout>
  );
}
