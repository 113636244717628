import {
  faQuestionCircle,
  faSave,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Form, Tab, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { getBrokersAction } from '../../actions/broker';
import { equipmentTypeOptions } from '../../actions/directory';
import { driversOptions } from '../../actions/drivers';
import {
  changeInvocesAction,
  deleteInvocesAction,
  getSelectedInvoiceAction,
  setInvocesAction,
} from '../../actions/invoces';
import { useInvoiceCache } from '../../api/InvoicesAPI';
import { currencyMoneyFormat } from '../../utils/DataUtils';
import { Input } from '../form/Input';
import Charge from './Charge';
import Deduction from './Deduction';
import DropFileInvoice from './DropFileInvoice';

const loadEquipmentTypeOptions = async (inputValue = '') => {
  const response = await equipmentTypeOptions();
  const data = response.data;
  const optionList =
    data &&
    data
      .filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((item) => ({
        value: item.id,
        label: item.name,
      }));
  return optionList;
};

const loadDriverOptions =
  (carrierID) =>
  async (inputValue = '') => {
    const response = await driversOptions(carrierID);
    const data = response.data;
    const optionList =
      data &&
      data
        .filter((item) =>
          item.name.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((item) => ({
          value: item.id,
          label: item.name,
        }));
    return optionList;
  };

const loadBrokersOptions = async (inputValue = '') => {
  const response = await getBrokersAction();
  const data = response.data;
  const optionList =
    data &&
    data
      .filter((item) =>
        item.debtorCompanyName.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((item) => ({
        value: item.id,
        label: item.debtorCompanyName,
      }));
  return optionList;
};

function calculateBalance(
  isCarrier,
  {
    lineHaul = 0,
    lessAdvancesAmount = 0,
    detentionAmount = 0,
    lumperPaidAmount = 0,
  },
  deductionList,
  chargeList
) {
  return (
    Number(lineHaul) -
    Number(lessAdvancesAmount) +
    Number(detentionAmount) +
    (isCarrier ? -Number(lumperPaidAmount) : Number(lumperPaidAmount)) -
    deductionList.reduce((total, { amount }) => total + Number(amount), 0) -
    chargeList.reduce((total, { amount }) => total + Number(amount), 0)
  );
}

function BuildNewInvoices({ match }) {
  const { invalidateInvoices } = useInvoiceCache();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm();
  const values = watch();

  const { id } = match.params;
  const isChange = id > -1;

  const [invoice, setInvoice] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [selectedEquipmentTypeOption, setSelectedEquipmentTypeOption] =
    useState({ value: 14, label: 'Dry Van' });
  const [selectedDriverOption, setSelectedDriverOption] = useState(null);
  const [selectedBrokerOption, setSelectedBrokerOption] = useState(null);

  const [deductionList, setDeductionList] = useState([]);
  const [chargeList, setChargeList] = useState([]);

  const [paidBy, setPaidBy] = useState(true);

  const [rate, setRate] = useState(null);
  const [bol, setBol] = useState(null);
  const [limper, setLimper] = useState(null);
  const [scaleTicket, setScaleTicket] = useState(null);
  const [other, setOther] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const allEquipmentTypeOptions = await loadEquipmentTypeOptions();
      if (isChange) {
        const responseInvoice = await getSelectedInvoiceAction(id);
        setInvoice(responseInvoice);

        const et = allEquipmentTypeOptions.filter(
          (item) => item.label === responseInvoice.equipmentType
        );
        setSelectedEquipmentTypeOption(et[0]);

        const { driver, broker } = responseInvoice;
        setSelectedDriverOption({
          value: driver.id,
          label: driver.name,
        });

        setSelectedBrokerOption({
          value: broker.id,
          label: broker.debtorCompanyName,
        });
        setStartDate(new Date(responseInvoice.deliveryDate));
        setDeductionList(responseInvoice.deductions);
        setChargeList(responseInvoice.charges);
        setPaidBy(responseInvoice.paidBy);
        setRate(responseInvoice.rate);
        setBol(responseInvoice.bol);
        setLimper(responseInvoice.limper);
        setScaleTicket(responseInvoice.scaleTicket);
        setOther(responseInvoice.other);

        reset(responseInvoice);
      }
    };
    fetchData();
  }, [reset]);

  const onSumbit = async (data) => {
    let invoiceInfo = { ...data, paidBy, chargeList, deductionList };
    if (selectedDriverOption === null) {
      alert('Pleace Selected Driver');
      return;
    } else
      invoiceInfo = { ...invoiceInfo, driverId: selectedDriverOption.value };

    if (startDate === null) {
      alert('Pleace Selected Delivery Date');
      return;
    } else invoiceInfo = { ...invoiceInfo, deliveryDate: startDate };
    if (selectedEquipmentTypeOption === null) {
      alert('Pleace Selected Equipment Type');
      return;
    } else
      invoiceInfo = {
        ...invoiceInfo,
        equipmentTypeId: selectedEquipmentTypeOption.value,
      };

    if (selectedBrokerOption === null) {
      alert('Pleace Selected Bill To');
      return;
    } else
      invoiceInfo = { ...invoiceInfo, brokerId: selectedBrokerOption.value };

    if (rate === null) {
      alert('Pleace Selected Rate Con.');
      return;
    } else invoiceInfo = { ...invoiceInfo, rateId: rate.id };

    if (bol === null) {
      alert('Pleace Selected BOL');
      return;
    } else invoiceInfo = { ...invoiceInfo, bolId: bol.id };

    if (limper !== null) invoiceInfo = { ...invoiceInfo, limperId: limper.id };

    if (scaleTicket !== null)
      invoiceInfo = { ...invoiceInfo, scaleTicketId: scaleTicket.id };

    if (other !== null) invoiceInfo = { ...invoiceInfo, otherId: other.id };

    if (isChange) {
      const responseInvoice = await changeInvocesAction(id, invoiceInfo);
      invalidateInvoices();

      if (responseInvoice) {
        history.goBack();
      }
    } else {
      const responseInvoice = await setInvocesAction(invoiceInfo);
      invalidateInvoices();

      if (responseInvoice) {
        history.goBack();
      }
    }
  };

  const handleClickRemove = async () => {
    const conf = window.confirm(`Are you sure?`);
    if (conf) {
      await deleteInvocesAction(id);
      history.push('/');
    }
  };

  const handlesetSameAsLoad = (checked) => {
    if (checked) {
      const { reference } = getValues();
      setValue('invoice', reference);
    }
  };

  return (
    <div className="ibox-content">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {isChange ? <h2>Change Invoice</h2> : <h2>New Invoice</h2>}
        <h3>
          <strong>
            {invoice.balance ? (
              <span>{currencyMoneyFormat(invoice.balance)}</span>
            ) : (
              <span>
                {currencyMoneyFormat(
                  calculateBalance(paidBy, values, deductionList, chargeList)
                )}
              </span>
            )}
          </strong>
        </h3>
        {/* <div><Button className="invoice-btn-two btn-sm" > Detail View</Button></div> */}
      </div>
      <hr className="mt-0" />
      <div className="row">
        <Form style={{ display: 'flex' }} onSubmit={handleSubmit(onSumbit)}>
          <div className="p-2" style={{ flex: '1 1 25%' }}>
            <Form.Group className="mb-3">
              <Form.Label
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <b>
                  Reference #<span className="error-simbol" />
                </b>
                <span
                  className="d-inline-block"
                  tabindex="0"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="This will be on your Rate Confirmation sheet. It is a sequence of numbers and/or letters your customer uses to reference the load."
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Load Number"
                {...register('reference', {
                  required: 'This is a required field',
                  minLength: 2,
                  maxLength: 50,
                })}
              />
              {errors.reference && (
                <p className="error">{errors.reference.message}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <b>
                  Invoice #<span className="error-simbol" />
                </b>
                <div style={{ display: 'flex' }}>
                  <Form.Check
                    style={{ marginRight: '.5rem' }}
                    type="checkbox"
                    onChange={(e) =>
                      handlesetSameAsLoad(e.currentTarget.checked)
                    }
                    label="Same As Load #"
                  />
                  <span
                    className="d-inline-block"
                    tabindex="0"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="A sequence of numbers chosen by you, our client. This is how your invoices will be labeled. This is not given by your customer."
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </span>
                </div>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Number Invoice"
                {...register('invoice', {
                  required: 'This is a required field',
                  minLength: 2,
                  maxLength: 50,
                })}
              />
              {errors.invoice && (
                <p className="error">{errors.invoice.message}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <b>
                  Driver
                  <span className="error-simbol" />
                </b>
              </Form.Label>
              <AsyncSelect
                cacheOptions
                key={invoice?.carrier?.id}
                loadOptions={loadDriverOptions(invoice?.carrier?.id)}
                defaultOptions
                onChange={(value) => setSelectedDriverOption(value)}
                value={selectedDriverOption}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: '.25rem',
                  colors: {
                    ...theme.colors,
                    primary25: '#f3f3f3',
                    primary: '#396cae',
                  },
                })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <b>
                  Pickup Location
                  <span className="error-simbol" />
                </b>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Initial Pickup Zip Code"
                {...register('pickupLocation', {
                  required: 'This is a required field',
                  minLength: 2,
                  maxLength: 50,
                })}
              />
              {errors.pickupLocation && (
                <p className="error">{errors.pickupLocation.message}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <b>
                  Delivery Location
                  <span className="error-simbol" />
                </b>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Final Dilevery Zip Code"
                {...register('deliveryLocation', {
                  required: 'This is a required field',
                  minLength: 2,
                  maxLength: 50,
                })}
              />
              {errors.deliveryLocation && (
                <p className="error">{errors.deliveryLocation.message}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <b>Delivery Date</b>
                <span
                  className="d-inline-block"
                  tabindex="0"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="The actual date of delivery – this can sometimes be different than what the Rate Confirmation states."
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              </Form.Label>
              <DatePicker
                className="form-control"
                selected={startDate}
                {...register('deliveryDate')}
                onChange={(date) => setStartDate(date)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <b>
                  Bill To <span className="error-simbol" />
                </b>
                <span
                  className="d-inline-block"
                  tabindex="0"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="The company name/customer you are doing the load for. This is who TAFS will bill."
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              </Form.Label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                isClearable
                loadOptions={loadBrokersOptions}
                getOptionValue={(option) => option.value}
                onChange={(value) => setSelectedBrokerOption(value)}
                value={selectedBrokerOption}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: '.25rem',
                  colors: {
                    ...theme.colors,
                    primary25: '#f3f3f3',
                    primary: '#396cae',
                  },
                })}
              />
              <Form.Text className="text-muted">
                Note: If the debtor name is not found, please go to the TAFS
                Credit Check website to enter the debtor. Turnaround time for
                entering in new debtors could take 20 minutes. Once the debtor
                is entered, return here to finish entering the invoice.
              </Form.Text>
            </Form.Group>
          </div>
          <div className="p-2" style={{ flex: '1 1 25%' }}>
            <Form.Group className="mb-3">
              <Form.Label>
                <b>
                  Equipment Type
                  <span className="error-simbol" />
                </b>
              </Form.Label>
              <AsyncSelect
                cacheOptions
                loadOptions={loadEquipmentTypeOptions}
                defaultOptions
                getOptionValue={(option) => option.value}
                onChange={(value) => setSelectedEquipmentTypeOption(value)}
                value={selectedEquipmentTypeOption}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: '.25rem',
                  colors: {
                    ...theme.colors,
                    primary25: '#f3f3f3',
                    primary: '#396cae',
                  },
                })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <b>
                  Linehaul
                  <span className="error-simbol" />
                </b>
                <span
                  className="d-inline-block"
                  tabindex="0"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="The flat rate. The amount your customer agreed to pay. "
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              </Form.Label>
              <Input
                prefix="$"
                placeholder="0.00"
                {...register('lineHaul', {
                  required: 'This is a required field',
                  minLength: 0,
                })}
              />
              {errors.lineHaul && (
                <p className="error">{errors.lineHaul.message}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <b>Less Advances Amount</b>
                <span
                  className="d-inline-block"
                  tabindex="0"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="This would be if you took a fuel advance from your customer"
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              </Form.Label>
              <Input
                prefix="$"
                type="text"
                placeholder="0.00"
                {...register('lessAdvancesAmount')}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <b>Detention Amount</b>
                <span
                  className="d-inline-block"
                  tabindex="0"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Extra pay from your customer for wait times at the receiver/shipper."
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              </Form.Label>
              <Input
                prefix="$"
                type="text"
                placeholder="0.00"
                {...register('detentionAmount')}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <b>Lumper Paid Amount</b>
                <span
                  className="d-inline-block"
                  tabindex="0"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Fee for the load/unloading the trailer."
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              </Form.Label>
              <Input
                type="text"
                prefix="$"
                placeholder="0.00"
                {...register('lumperPaidAmount')}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <div style={{ display: 'flex', justifyContent: 'row' }}>
                <Form.Label className="m-2">
                  <b>
                    Paid By <span className="error-simbol" />
                  </b>
                </Form.Label>
                <Form.Check
                  inline
                  label="Carrier"
                  name="group1"
                  type="radio"
                  className="m-2"
                  style={{ fontSize: '16px' }}
                  checked={paidBy}
                  onChange={(e) => setPaidBy(true)}
                />
                <Form.Check
                  inline
                  label="Broker"
                  name="group1"
                  type="radio"
                  className="m-2"
                  style={{ fontSize: '16px' }}
                  checked={!paidBy}
                  onChange={() => setPaidBy(false)}
                />
              </div>
              <hr />
            </Form.Group>
            <Form.Group className="mb-3">
              <Deduction
                deductionState={deductionList}
                setDeductionState={setDeductionList}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Charge chargeState={chargeList} setChargeState={setChargeList} />
            </Form.Group>
          </div>
          <div className="p-2" style={{ flex: '1 1 50%' }}>
            <div className="p-3 m-2">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="home"
                  title={
                    <span>
                      {'Rate Con.'}
                      <span className="error-simbol" />
                    </span>
                  }
                >
                  <DropFileInvoice
                    tabKey="rate"
                    fileState={rate}
                    setFileState={setRate}
                  />
                </Tab>
                <Tab
                  eventKey="profile"
                  title={
                    <span>
                      {'BOL'}
                      <span className="error-simbol" />
                    </span>
                  }
                >
                  <DropFileInvoice
                    tabKey="bol"
                    fileState={bol}
                    setFileState={setBol}
                  />
                </Tab>
                <Tab eventKey="contact" title="Lumper Receipt">
                  <DropFileInvoice
                    tabKey="limper"
                    fileState={limper}
                    setFileState={setLimper}
                  />
                </Tab>
                <Tab eventKey="dis" title="Scale Ticket">
                  <DropFileInvoice
                    tabKey="scaleTicket"
                    fileState={scaleTicket}
                    setFileState={setScaleTicket}
                  />
                </Tab>
                <Tab eventKey="other" title="Other">
                  <DropFileInvoice
                    tabKey="other"
                    fileState={other}
                    setFileState={setOther}
                  />
                </Tab>
              </Tabs>
              {/* <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label><b>Reason</b></Form.Label>
                                <Form.Control as="textarea" rows={3} {...register("reason")} />
                            </Form.Group> */}
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Message</b>
                </Form.Label>
                <Form.Control as="textarea" rows={3} {...register('message')} />
              </Form.Group>
              <div className="input-group pull-right">
                <Button
                  className="m-1"
                  variant="secondary"
                  onClick={() => history.push('/')}
                >
                  Cancel
                </Button>
                <Button className="invoice-btn m-1" type="submit">
                  <FontAwesomeIcon icon={faSave} /> Save Invoice
                </Button>
                {isChange ? (
                  <Button
                    className="invoice-btn-two m-1"
                    onClick={handleClickRemove}
                  >
                    {' '}
                    <FontAwesomeIcon icon={faTrash} /> Delete Invoice
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default BuildNewInvoices;
