const SET_INVOCES = 'SET_INVOCES';
const SET_TAB = 'SET_TAB';
const SET_FILTER = 'SET_FILTER';
const SET_IS_FETCHING = 'SET_IS_FETCHING';
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
const SET_FETCH_ERROR = 'SET_FETCH_ERROR';

const defaultState = {
  items: [],
  filter: {},
  tab: 'Unsubmitted',
  isFetching: true,
  currentPage: 1,
  perPage: 10,
  totalPages: 0,
  isFetchError: false,
};

export default function invoceReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_INVOCES:
      return {
        ...state,
        items: action.payload.data,
        currentPage: action.payload.pageNumber,
        totalPages: action.payload.totalPages,
        isFetching: false,
      };
    case SET_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_FETCH_ERROR:
      return {
        ...state,
        isFetchError: action.payload,
      };
    default:
      return state;
  }
}

export const setInvoces = (repos) => ({ type: SET_INVOCES, payload: repos });
export const setTabInvoce = (tab) => ({ type: SET_TAB, payload: tab });
export const setFilterInvoce = (filter) => ({
  type: SET_FILTER,
  payload: filter,
});
export const setIsFetching = (bool) => ({
  type: SET_IS_FETCHING,
  payload: bool,
});
export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});
export const setFetchError = (bool) => ({
  type: SET_FETCH_ERROR,
  payload: bool,
});
