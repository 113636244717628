import { faHome, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { searchInvoice } from '../../api/InvoicesAPI';
import { logout } from '../../reducers/carrierReducer';
import './AdmNavbar.scss';

function AdmNavbar(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <nav className="navbar navbar-expand navbar-light bg-light justify-content-between">
      <div className="navbar-brand" style={{ display: 'flex' }}>
        <AsyncSelect
          id="header_invoice_search_txt_search"
          placeholder="Search for an invoice..."
          className="form-control txt_search"
          defaultOptions
          loadOptions={async (search) => {
            const response = await searchInvoice(search);
            return response.data.data.map((item) => {
              return {
                value: item.id,
                label: `${item.reference} ${item.invoice} $ ${item.amount} ${item.carrierBusinessName}`,
              };
            });
          }}
          onChange={({ value }) => {
            history.push(`/manage-invoice/${value}`);
          }}
          styles={{
            container: (provided) => ({
              ...provided,
              zIndex: 100,
            }),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: '.25rem',
            colors: {
              ...theme.colors,
              primary25: '#f3f3f3',
              primary: '#396cae',
            },
          })}
        />
      </div>
      <div id="navbarText">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link navbar-link" to="/invoices">
              <FontAwesomeIcon icon={faHome} /> Home
            </Link>
          </li>
          <li className="nav-item">
            <a
              href="#"
              className="nav-link navbar-link"
              onClick={() => dispatch(logout())}
            >
              <FontAwesomeIcon icon={faSignOutAlt} /> Log Out
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default AdmNavbar;
