import { differenceInMilliseconds, format, parseISO } from 'date-fns';

export function parseDate(date) {
  return parseISO(date);
}

export function stringifyDate(date) {
  return format(date, 'yyyy-MM-dd');
}

export function formatDate(date) {
  return format(parseDate(date), 'MM/dd/yyyy');
}

export function formatTime(date) {
  return format(parseDate(date), 'h:mm:ss aa');
}

export function formatDateTime(date) {
  return `${formatDate(date)} ${formatTime(date)}`;
}

export function diffDate(a, b) {
  return differenceInMilliseconds(parseDate(a), parseDate(b));
}
