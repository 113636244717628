import jwt_decode from 'jwt-decode';
import { $authHost, $host } from '../api/config';
import {
  logout,
  setCarriers,
  setCurrentCarries,
  setFetchError,
  setIsFetching,
} from '../reducers/carrierReducer';

export const loginCarrier = (email, password) => {
  return async (dispatch) => {
    try {
      dispatch(setIsFetching(true));
      const response = await $host.get(`/api/carrier/token`, {
        params: {
          email: email,
          password: password,
        },
      });
      sessionStorage.setItem('token', response.data);
      const jwtInfo = jwt_decode(response.data);
      const responseInfo = await $authHost.get('/api/carrier/me');
      dispatch(setCurrentCarries({ ...responseInfo.data, role: jwtInfo.role }));
    } catch (e) {
      dispatch(setIsFetching(false));
      const status_code = e.response.status;

      switch (status_code) {
        case 404:
          return alert(
            'User not found. Please check the correctness of the entered data'
          );
        default:
          return alert(
            `Error has occurred. Please check the correctness of the entered data. ${e}`
          );
      }
    }
  };
};

export const check = () => {
  return async (dispatch) => {
    const token = sessionStorage.getItem('token');
    if (token) {
      const jwtInfo = jwt_decode(token);
      const responseInfo = await $authHost.get('/api/carrier/me');
      dispatch(setCurrentCarries({ ...responseInfo.data, role: jwtInfo.role }));
    } else dispatch(logout());
  };
};

export const aboutCarrier = async () => {
  try {
    const response = await $authHost.get('/api/carrier/me');
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    const status_code = e.response.status;

    switch (status_code) {
      case 404:
        return alert('Not found.');
      case 403:
        return alert('Your session has expired');
      default:
        return alert(e);
    }
  }
};

export const carrierOptions = async () => {
  const response = await $authHost.get(`/api/carrier/`);

  if (response.status === 200) {
    return response.data;
  }
};

export const getCarriersAction = (currentPage) => {
  return async (dispatch) => {
    try {
      dispatch(setIsFetching(true));
      const response = await $authHost.get(`/api/carrier/`, {
        params: {
          page: currentPage,
        },
      });
      dispatch(setCarriers(response.data));
    } catch (e) {
      dispatch(setFetchError(true));
      dispatch(setIsFetching(false));
      setTimeout(() => {
        dispatch(setFetchError(false));
      }, 2000);
    }
  };
};

export const getSelectedCarrierAction = async (carrierID) => {
  try {
    const response = await $authHost.get(`/api/carrier/${carrierID}`, {
      headers: {
        'Content-Type': ' application/json',
      },
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return e.response.data;
  }

  return {};
};

export const addCarrierAction = async (payload) => {
  try {
    const response = await $authHost.post(
      `/api/carrier`,
      JSON.stringify(payload),
      {
        headers: {
          'Content-Type': ' application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    const status_code = e.response.status;

    switch (status_code) {
      case 400:
        return alert(
          'Incorrect input. Please check the correctness of the entered data'
        );
      case 404:
        return alert('Please check the correctness of the entered data');
      default:
        return alert(
          `Error has occurred. Please check the correctness of the entered data. ${e}`
        );
    }
  }
};

export const changeCarrierAction = async (carrierID, payload) => {
  try {
    const response = await $authHost.put(
      `/api/carrier/${carrierID}`,
      JSON.stringify(payload),
      {
        headers: {
          'Content-Type': ' application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    return null;
  }
};

export const deleteCarrierAction = async (carrierID) => {
  try {
    const response = await $authHost.delete(`/api/carrier/${carrierID}`, {
      headers: {
        'Content-Type': ' application/json',
      },
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    alert('Error to delete driver');
    return {};
  }
};
