function Settings() {
  return (
    <div className="ibox-content">
      <div className="row">
        <div className="col-lg-9 col-md-6 col-sm-12">
          <h2>Settings</h2>
        </div>
      </div>
    </div>
  );
}

export default Settings;
