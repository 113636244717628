import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { getBrokersAction } from '../actions/broker';
import MyAction from '../components/admcarriers/MyAction';

function AdmBrokers() {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [{ data: brokers, totalPages }, setBrokers] = useState({
    data: [],
    totalPages: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      setBrokers(await getBrokersAction());
    };
    fetchData(page);
  }, [page]);

  return (
    <div>
      <div className="pl-3" style={{ margin: '0px 10px' }}>
        <div className="row">
          <div className="p-0 m-0">
            <MaterialTable
              title={<h2 style={{ margin: '1.5rem 0rem' }}>Brokers</h2>}
              columns={[
                { title: 'id', field: 'id', hidden: true },
                { title: 'Debtor Company Name', field: 'debtorCompanyName' },
                { title: 'Address', field: 'address' },
                { title: 'Phone Number', field: 'phone' },
                { title: 'Contact Name', field: 'contactName' },
                { title: 'Contact Email', field: 'contactEmail' },
                { title: 'Requester Name', field: 'requesterName' },
                { title: 'Requester Phone', field: 'requesterPhone' },
              ]}
              data={brokers}
              onRowClick={(evt, selectedRow) => {
                history.push(`/broker/${selectedRow.id}`);
              }}
              totalCount={totalPages * 10}
              page={page}
              onChangePage={(page) => {
                setPage(page);
              }}
              options={{
                pageSizeOptions: [],
                selection: true,
                pageSize: 10,
              }}
              components={{
                Action: (props) => <MyAction {...props} />,
                Toolbar: (props) => (
                  <div style={{ color: '#676a6c' }}>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              actions={[
                {
                  tooltip: 'Remove All Selected Rows',
                  icon: 'delete',
                  onClick: (evt, data) => {
                    // const conf = window.confirm(`Are you sure?`);
                    // if (conf) {
                    //     //TO DO:
                    // }
                  },
                },
                {
                  icon: () => (
                    <Button
                      className="invoice-btn"
                      onClick={() => history.push('/broker')}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add New Broker
                    </Button>
                  ),
                  onClick: (event, rowData) => {
                    //TO DO:
                  },
                  isFreeAction: true,
                  tooltip: 'Add New Broker',
                },
              ]}
              localization={{
                toolbar: { searchPlaceholder: 'Search for a Broker' },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdmBrokers;
