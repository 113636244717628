import {
  faCheckCircle,
  faEnvelope,
  faExchangeAlt,
  faExclamationCircle,
  faFilter,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialTable from 'material-table';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from 'react-router-query-hooks';
import {
  INVOICE_STATUS,
  useAdminInvoices,
  useChangeInvoiceStatus,
  useChangeInvoiceStatusBulk,
} from '../../api/AdminInvoicesAPI';
import { useLocalStorage } from '../../hooks/storage';
import { currencyMoneyFormat } from '../../utils/DataUtils';
import AdminInvoiceFilterModal from './components/AdminInvoiceFilterModal';
import { ChangeStatusInvoiceContainer } from './containers/ChangeStatusInvoiceContainer';
import { ModalInvoiceSendMailContainer } from './containers/ModalInvoiceSendMail';
import { RequiresAttentionModal } from './containers/RequiresAttentionModal';
import { SendBrokerModal } from './containers/SendBrokerModal';

function AdminInvoicesPage() {
  const history = useHistory();
  const [query] = useQueryParams();

  const statusGroup = query.statusGroup;
  const statusId = query.statusId && Number(query.statusId);

  const [modalState, setModalState] = useState();
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useLocalStorage('invoice_filter', {});
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const { mutate: changeStatus } = useChangeInvoiceStatus();
  const { mutate: changeStatusBulk, isLoading: isBulkChanging } =
    useChangeInvoiceStatusBulk(() => setSelectedInvoices([]));

  const { data } = useAdminInvoices({
    ...filter,
    page,
    size,
    statusId,
    statusGroup,
  });

  return (
    <div className="ibox-content">
      <AdminInvoiceFilterModal
        show={modalState?.type === 'filter'}
        value={filter}
        handleClose={() => setModalState(undefined)}
        onSubmit={(values) => {
          setModalState(undefined);
          setFilter(values);
        }}
      />

      <ChangeStatusInvoiceContainer
        statusId={statusId}
        show={modalState?.type === 'change-status'}
        invoiceId={modalState?.invoiceId}
        handleClose={() => setModalState(undefined)}
        onSuccess={() => {
          setModalState(undefined);
        }}
      />

      <SendBrokerModal
        show={modalState?.type === 'send-broker'}
        invoiceId={modalState?.invoiceId}
        handleClose={() => setModalState(undefined)}
      />

      <ModalInvoiceSendMailContainer
        show={modalState?.type === 'mail'}
        invoiceId={modalState?.invoiceId}
        handleClose={() => setModalState(undefined)}
      />

      <RequiresAttentionModal
        show={modalState?.type === 'requires-attention'}
        invoiceId={modalState?.invoiceId}
        handleClose={() => setModalState(undefined)}
      />

      <div className="row">
        <div className="col-lg-9 col-md-6 col-sm-12">
          <h2>Invoices</h2>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <Button
          variant="outline-secondary"
          className={
            Object.entries(filter).length === 0
              ? 'btn-default btn-sm'
              : 'invoice-btn btn-sm'
          }
          title="Filter the invoices in more detail."
          onClick={(e) => setModalState({ type: 'filter' })}
        >
          <FontAwesomeIcon icon={faFilter} />
        </Button>

        {(statusId === INVOICE_STATUS.SUBMITTED ||
          statusId === INVOICE_STATUS.REVIEWED ||
          statusId === INVOICE_STATUS.REQUIRES_ATTENTION ||
          statusId === INVOICE_STATUS.DELAYED_ADVANCE ||
          statusId === INVOICE_STATUS.VERIFIED) && (
          <Button
            disabled={isBulkChanging}
            className="invoice-btn m-1"
            onClick={() => {
              if (selectedInvoices.length > 0) {
                changeStatusBulk(
                  selectedInvoices.map((invoice) => ({
                    invoiceId: invoice.id,
                    invoiceStatusId:
                      statusId === INVOICE_STATUS.VERIFIED
                        ? INVOICE_STATUS.APPROVED
                        : INVOICE_STATUS.VERIFIED,
                  }))
                );
              } else {
                alert('Please select invoices');
              }
            }}
          >
            {statusId === INVOICE_STATUS.VERIFIED
              ? 'Approve Invoices'
              : 'Verify Invoices'}
          </Button>
        )}
      </div>

      <MaterialTable
        data={data?.data}
        columns={[
          {
            title: 'id',
            field: 'id',
            hidden: true,
          },
          {
            title: 'Carrier',
            field: 'carrierBusinessName',
            headerStyle: {
              fontWeight: '600',
              padding: '0.5rem',
            },
          },
          {
            title: 'Debtor',
            field: 'broker',
            headerStyle: {
              fontWeight: '600',
              padding: '0.5rem',
            },
          },
          {
            title: 'Invoice #',
            field: 'invoice',
            headerStyle: {
              fontWeight: '600',
              padding: '0.5rem',
            },
          },
          {
            title: 'Reference #',
            field: 'reference',
            headerStyle: {
              fontWeight: '600',
              padding: '0.5rem',
            },
          },
          {
            title: 'Amount',
            field: 'amount',
            render: (rowData) => {
              return <>{currencyMoneyFormat(rowData.amount)}</>;
            },
            headerStyle: {
              fontWeight: '600',
              padding: '0.5rem',
            },
          },
          {
            hidden: !(
              statusId === INVOICE_STATUS.PAID ||
              statusId === INVOICE_STATUS.SHORT_PAY ||
              statusId === INVOICE_STATUS.OVER_PAY
            ),
            title: 'Broker Paid',
            field: 'brokerPaid',
            render: (rowData) => {
              return (
                <>
                  {rowData.brokerPaid != null &&
                    currencyMoneyFormat(rowData.brokerPaid)}
                </>
              );
            },
            headerStyle: {
              fontWeight: '600',
              padding: '0.5rem',
            },
          },
          {
            title: 'Pickup',
            field: 'pickupLocation',
            headerStyle: {
              fontWeight: '600',
              padding: '0.5rem',
            },
          },
          {
            title: 'Delivery',
            field: 'deliveryLocation',
            headerStyle: {
              fontWeight: '600',
              padding: '0.5rem',
            },
          },
          {
            title: 'Delivery Date',
            field: 'deliveryDate',
            headerStyle: {
              fontWeight: '600',
              padding: '0.5rem',
            },
          },
          {
            title: 'Advance Type',
            field: 'paymentOptionName',
            headerStyle: {
              fontWeight: '600',
              padding: '0.5rem',
            },
          },
          {
            title: 'Created Date',
            field: 'createAt',
            render: (rowData) => {
              const curDate = new Date(rowData.createAt);
              return <>{curDate.toISOString().split('T')[0]}</>;
            },
            headerStyle: {
              fontWeight: '600',
              padding: '0.5rem',
            },
          },
          {
            title: 'Since Invoiced',
            field: 'sinceInvoiced',
            headerStyle: {
              fontWeight: '600',
              padding: '0.5rem',
            },
            hidden: statusId !== INVOICE_STATUS.INVOICED,
          },
          {
            title: 'Status',
            field: 'status',
            headerStyle: {
              fontWeight: '600',
              padding: '0.5rem',
            },
          },
        ]}
        page={page}
        totalCount={(data?.totalPages || 0) * size}
        onChangePage={(value) => {
          setPage(value);
        }}
        style={{ boxShadow: 'none', fontSize: '13px' }}
        onRowClick={(evt, selectedRow) => {
          history.push(`/manage-invoice/${selectedRow.id}`);
        }}
        selectionProps={({ id }) => {
          return { checked: selectedInvoices.find((x) => x.id === id) };
        }}
        onSelectionChange={(rows) => {
          setSelectedInvoices(rows);
        }}
        onChangeRowsPerPage={(value) => {
          setSize(value);
        }}
        options={{
          search: false,
          showTitle: false,
          toolbar: false,
          pageSize: size,
          selection: true,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            position: 'row',
            icon: () => <FontAwesomeIcon icon={faExchangeAlt} />,
            tooltip: 'Change Status',
            onClick: (event, rowData) => {
              setModalState({ type: 'change-status', invoiceId: rowData.id });
            },
          },
          {
            position: 'row',
            hidden: statusId !== INVOICE_STATUS.SUBMITTED,
            icon: () => <FontAwesomeIcon icon={faCheckCircle} />,
            tooltip: 'Verify',
            onClick: (event, rowData) => {
              changeStatus({
                invoiceId: rowData.id,
                invoiceStatusId: INVOICE_STATUS.VERIFIED,
              });
            },
          },
          {
            position: 'row',
            hidden: statusId !== INVOICE_STATUS.SUBMITTED,
            icon: () => <FontAwesomeIcon icon={faExclamationCircle} />,
            tooltip: 'Requires attention',
            onClick: (event, rowData) => {
              setModalState({
                type: 'requires-attention',
                invoiceId: rowData.id,
              });
            },
          },
          {
            position: 'row',
            hidden: statusId !== INVOICE_STATUS.SUBMITTED,
            icon: () => <FontAwesomeIcon icon={faEnvelope} />,
            tooltip: 'Send Mail',
            onClick: (event, rowData) => {
              setModalState({ type: 'mail', invoiceId: rowData.id });
            },
          },
          {
            position: 'row',
            hidden: statusId !== INVOICE_STATUS.APPROVED,
            icon: () => <FontAwesomeIcon icon={faPaperPlane} />,
            tooltip: 'Send Broker',
            onClick: (event, rowData) => {
              setModalState({ type: 'send-broker', invoiceId: rowData.id });
            },
          },
        ]}
      />
    </div>
  );
}

export default AdminInvoicesPage;
