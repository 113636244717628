import { Button, Form, Modal } from 'react-bootstrap';
import { Form as Formik } from 'react-bootstrap-formik';

export const InvoiceCommentModal = ({ handleClose }) => {
  return (
    <>
      <Modal.Header
        closeButton
        style={{ background: '#396cae', color: '#fff' }}
      >
        <Modal.Title>
          <h3 className="p-0 m-0">Add comment</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-2">
          <Formik.Input
            name="comment"
            type="text"
            placeholder="Enter Comment"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button type="submit" className="invoice-btn">
          Add
        </Button>
      </Modal.Footer>
    </>
  );
};

export default InvoiceCommentModal;
