import { faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  addFileInvoice,
  deleteFileInvoice,
  downLoadFileInvoice,
} from '../../actions/fileInvoice';
import fileIcon from '../../assets/file.png';

const styleZone = {
  border: '2px dashed  hsl(0, 0%, 80%)',
  padding: '3rem',
  marginBottom: '1rem',
  textAlign: 'center',
  color: '#676a6c',
  fontSize: '16px',
};

const DropFileInvoice = (props) => {
  const { tabKey, fileState, setFileState } = props;
  const onDrop = useCallback(async (acceptedFiles) => {
    const response = await addFileInvoice(tabKey, acceptedFiles[0]);
    setFileState(response);
  });

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop,
  });

  const setType = (type) => {
    const arrTypes = [
      { key: 'rate', value: 'Rate Con.' },
      { key: 'bol', value: 'BOL' },
      { key: 'limper', value: 'Lumper Receipt' },
      { key: 'scaleTicket', value: 'Scale Ticket' },
    ];

    const selectedType = arrTypes.filter((item) => item.key.indexOf(type) > -1);
    if (selectedType.length > 0) return selectedType[0].value;
    else return type;
  };
  const handleRemove = async (fieId) => {
    const conf = window.confirm(`Are you sure?`);
    if (conf) {
      const response = await deleteFileInvoice(fieId);
      alert(response.status);
      // setFiles(files.filter(item => item.id != response.entityId));
      setFileState(null);
    }
  };

  const handleDownload = async (fieId) => {
    return downLoadFileInvoice(fieId);
  };

  const fileUpload =
    fileState === null || Object.entries(fileState).length === 0 ? null : (
      <div className="p-2" key={fileState.path}>
        <div className="row">
          <div className="col-sm-1">
            <img src={fileIcon} style={{ width: '2.5rem' }} />
          </div>
          <div className="col-sm-6 mt-2">{fileState.name}</div>
          <div className="col-sm-3 mt-1">{setType(fileState.type)}</div>
          <div className="col-sm-2 mt-1">
            <div className="grid-icon-rows">
              <button
                className="grid-btn"
                type="button"
                title="Download"
                onClick={() => handleDownload(fileState.id)}
              >
                <FontAwesomeIcon icon={faDownload} />
              </button>
              <button
                className="grid-btn grid-btn__right"
                type="button"
                title="Delete"
                onClick={() => handleRemove(fileState.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <section className="container-fluid">
      <div className="form-uplad w-100">
        <div {...getRootProps({ className: 'dropzone', style: styleZone })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
      </div>
      {fileState && (
        <div className="files-upload mb-5">
          <>{fileUpload}</>
        </div>
      )}
    </section>
  );
};

export default DropFileInvoice;
