import { useField } from 'formik';
import Select from 'react-select';
import { useCharges } from '../../../api/InvoicesAPI';

export function ChargesField({ name }) {
  const [field, _, { setValue }] = useField(name);
  const { data = [] } = useCharges();

  return (
    <Select
      {...field}
      required
      onChange={({ id }) => {
        setValue(id);
      }}
      options={data}
      placeholder="Type of Charge"
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      value={data.find((item) => item.id === field.value)}
      theme={(theme) => ({
        ...theme,
        borderRadius: '.25rem',
        colors: {
          ...theme.colors,
          primary25: '#f3f3f3',
          primary: '#396cae',
        },
      })}
    />
  );
}
