import { forwardRef, useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Form as Formik } from 'react-bootstrap-formik';
import { currencyMoneyFormat } from '../../../utils/DataUtils';

function assignRef(ref, value) {
  if (ref) {
    if (typeof ref === 'function') {
      ref(value);
    } else if (typeof ref === 'object') {
      ref.current = value;
    }
  }
}

const CustomInput = forwardRef((props, forwardedRef) => {
  return (
    <input
      {...props}
      ref={(node) => {
        assignRef(forwardedRef, node);
        assignRef(props.innerRef, node);
      }}
    />
  );
});

export const InvoiceSubmitModalContent = ({
  invoiceCount,
  totalAmount,
  handleClose,
  paymentOptions,
}) => {
  const ref = useRef([]);
  const [focusedIndex, setFocusedIndex] = useState(0);

  useEffect(() => {
    requestAnimationFrame(() => {
      ref.current[focusedIndex]?.focus();
    });
  }, [focusedIndex]);

  return (
    <>
      <Modal.Header
        closeButton
        style={{ background: '#396cae', color: '#fff' }}
      >
        <Modal.Title>
          <h3 className="p-0 m-0">Batch Process</h3>{' '}
          <small style={{ fontSize: '.6em' }}>
            Please select a processing type{' '}
          </small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik.Group name="paymentOption">
          {paymentOptions.map((item, index, arr) => (
            <div key={item.id} className="mb-2">
              <Formik.Radio
                innerRef={(node) => {
                  ref.current[index] = node;
                }}
                label={item.name}
                name={item.id}
                className="m-2"
                as={CustomInput}
                tabIndex={focusedIndex === index ? 0 : -1}
                style={{ fontSize: '16px' }}
                onKeyDown={(event) => {
                  let nextIndex = NaN;

                  if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
                    nextIndex = index === 0 ? arr.length - 1 : index - 1;
                  }

                  if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
                    nextIndex = index === arr.length - 1 ? 0 : index + 1;
                  }

                  if (Number.isFinite(nextIndex)) {
                    // prevent scrolling and other default behavior
                    event.preventDefault();
                    setFocusedIndex(nextIndex);
                  }
                }}
              />
            </div>
          ))}
        </Formik.Group>
        <Form.Group className="mb-2" style={{ float: 'right' }}>
          <Form.Label>
            <b>
              Invoice count: {invoiceCount}. Total Amount:
              {currencyMoneyFormat(totalAmount)}
            </b>
          </Form.Label>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button type="submit" className="invoice-btn">
          Submit Batch
        </Button>
      </Modal.Footer>
    </>
  );
};

export const InvoiceSubmitModal = ({ show, handleClose, children }) => {
  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      {children}
    </Modal>
  );
};
