import { Form, FormikProvider, useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { commentInvoice } from '../../../api/AdminInvoicesAPI';
import { InvoiceCommentModal } from '../components/InvoiceCommentModal';

export function InvoiceCommentModalContent({ invoiceId, handleClose }) {
  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues: { comment: '' },
    onSubmit({ comment }) {
      return commentInvoice(invoiceId, comment).then(() => {
        queryClient.invalidateQueries(['invoices']);
        handleClose();
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <InvoiceCommentModal handleClose={handleClose} />
      </Form>
    </FormikProvider>
  );
}

export function InvoiceCommentModalContainer({ show, invoiceId, handleClose }) {
  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <InvoiceCommentModalContent {...{ invoiceId, handleClose }} />
    </Modal>
  );
}
