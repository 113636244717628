import React from 'react';
import AdmNavbar from '../admnavbar/AdmNavbar';
import AdmSidebar from '../admsidebar/AdmSidebar';
import Footer from '../footer/Footer';
import './Admlayout.scss';

const Admlayout = (props) => {
  return (
    <div className="adm-base-container">
      <div className="adm-base-container__left">
        <AdmSidebar />
      </div>
      <div className="adm-base-container__right">
        <AdmNavbar />
        <main>{props.children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default Admlayout;
