import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import Select from 'react-select';
import { addInvoiceFile } from '../../../api/AdminInvoicesAPI';
import { useInvoice } from '../../../api/InvoicesAPI';
import InvoiceFileDrop from '../components/InvoiceFileDrop';

function InvoiceFileModalContent({ invoiceId, handleClose }) {
  const [type, setType] = useState();

  const queryClient = useQueryClient();
  const { data } = useInvoice(invoiceId);

  if (!data) {
    return null;
  }

  const formik = useFormik({
    initialValues: { file: null },
    onSubmit({ file }) {
      if (!file || !type) {
        return;
      }
      return addInvoiceFile(invoiceId, type.value, file).then(() => {
        queryClient.invalidateQueries(['invoices']);
        handleClose();
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Modal.Header
          closeButton
          style={{ background: '#396cae', color: '#fff' }}
        >
          <Modal.Title>
            <h3 className="p-0 m-0">Add comment</h3>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Select
            value={type}
            onChange={setType}
            theme={(theme) => ({
              ...theme,
              borderRadius: '.25rem',
              colors: {
                ...theme.colors,
                primary25: '#f3f3f3',
                primary: '#396cae',
              },
            })}
            options={[
              !data.rate && { label: 'Rate', value: 'RATE' },
              !data.bol && { label: 'Bol', value: 'BOL' },
              !data.limper && { label: 'Lumper', value: 'LUMPER' },
              !data.scaleTicket && { label: 'Scale', value: 'SCALE' },
              !data.other && { label: 'Other', value: 'OTHER' },
              !data.revisedRateCon && {
                label: 'Revised Rate Con',
                value: 'REVISED_RATE_CON',
              },
              !data.other2 && { label: 'Other2', value: 'OTHER2' },
              !data.other3 && { label: 'Other3', value: 'OTHER3' },
            ].filter(Boolean)}
          />

          <InvoiceFileDrop
            file={formik.values.file}
            onDrop={([file]) => {
              formik.setFieldValue('file', file);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button type="submit" className="invoice-btn">
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </FormikProvider>
  );
}

export function InvoiceFileModalContainer({ show, invoiceId, handleClose }) {
  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <InvoiceFileModalContent {...{ invoiceId, handleClose }} />
    </Modal>
  );
}
