import { forwardRef } from 'react';
import { Form } from 'react-bootstrap';

export const Input = forwardRef(({ prefix, ...props }, ref) => (
  <div style={{ position: 'relative' }}>
    {prefix && (
      <span
        style={{
          left: 5,
          top: '50%',
          transform: 'translateY(-50%)',
          position: 'absolute',
        }}
      >
        {prefix}
      </span>
    )}
    <Form.Control ref={ref} type="text" {...props} />
  </div>
));
