import React from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { loginCarrier } from '../actions/carrier';
import logo from '../assets/logo.png';
import useInput from '../hooks/inputs';
import '../style/Auth.scss';

const Auth = () => {
  const dispatch = useDispatch();
  const login = useInput('', { isEmpty: true });
  const password = useInput('', { isEmpty: true });

  const handleSubmit = () => {
    dispatch(loginCarrier(login.value, password.value));
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <div className="box-container">
        <div className="box">
          <div className="box__logo">
            <img src={logo} alt="logo" />
          </div>
          <h5 className="box__w-text">BM Financial Portal</h5>
          <div className="box__form">
            <div className="form-group">
              {login.isDirty && login.isEmpty && (
                <div className="error">field cannot be empty</div>
              )}
              <input
                onChange={(e) => login.onChange(e)}
                onBlur={(e) => login.onBlur(e)}
                value={login.value}
                name="txt_username"
                type="text"
                className="form-control"
                placeholder="Username"
              />
            </div>
            <div className="form-group">
              {password.isDirty && password.isEmpty && (
                <div className="error">field cannot be empty</div>
              )}
              <input
                onChange={(e) => password.onChange(e)}
                onBlur={(e) => password.onBlur(e)}
                value={password.value}
                name="txt_password"
                type="password"
                id="txt_password"
                className="form-control"
                placeholder="Password"
              />
            </div>
            <div className="form-group">
              <Button
                type="submit"
                className="w-100 invoice-btn"
                disabled={!(login.isValid && password.isValid)}
              >
                Login
              </Button>
            </div>
          </div>
          <div className="box__footer">
            <p className="box__info">
              {' '}
              <small>Best if viewed with Chrome web browser.</small>{' '}
            </p>
            <p className="box__version">
              {' '}
              <small>
                Version: <span id="lbl_version">1.0.0</span>
              </small>{' '}
            </p>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Auth;
