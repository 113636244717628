import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getDriversAction } from '../actions/drivers';
import MyAction from '../components/admcarriers/MyAction';

const Drivers = () => {
  const history = useHistory();
  const carrier = useSelector((state) => state.carrier.currentCarrier);
  const { id } = carrier;

  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const drivers = useSelector((state) => state.driver.items);

  const currentPage = useSelector((state) => state.driver.currentPage);

  useEffect(() => {
    dispatch(getDriversAction(id, currentPage));
  }, []);

  return (
    <div>
      <div className="p-0 m-0">
        <MaterialTable
          title={<h2 style={{ margin: '1.5rem 0rem' }}>Manage Drivers</h2>}
          columns={[
            { title: 'id', field: 'id', hidden: true },
            { title: 'Name', field: 'name', cellStyle: { width: '20%' } },
            {
              title: 'Address',
              field: 'address',
              cellStyle: { width: '20%' },
            },
            { title: 'Email', field: 'email', cellStyle: { width: '20%' } },
            {
              title: 'Phone Number',
              field: 'phoneNumber',
              cellStyle: { width: '20%' },
            },
            { title: 'State', field: 'state', cellStyle: { width: '20%' } },
          ]}
          data={drivers}
          onRowClick={(evt, selectedRow) => {
            setSelectedRow(selectedRow.id);
            history.push(`/select-driver/${selectedRow.id}`);
          }}
          options={{
            selection: true,
            pageSize: 10,
            maxBodyHeight: `72vh`,
            minBodyHeight: `72vh`,
          }}
          components={{
            Action: (props) => <MyAction {...props} />,
            Toolbar: (props) => (
              <div style={{ color: '#676a6c' }}>
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          actions={[
            {
              tooltip: 'Remove All Selected Rows',
              icon: 'delete',
              onClick: (evt, selectedRows) => {
                const conf = window.confirm(`Are you sure?`);
                if (conf) {
                  //TO DO:
                  //     const selectIds = selectedRows.map(item => item.id);
                }
              },
            },
            {
              icon: () => (
                <Button
                  className="invoice-btn"
                  onClick={() => history.push('/select-driver')}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New Driver
                </Button>
              ),
              onClick: (event, rowData) => {
                //TO DO:
              },
              isFreeAction: true,
              tooltip: 'Add New Driver',
            },
          ]}
          localization={{
            toolbar: { searchPlaceholder: 'Search for a Driver' },
          }}
        />
      </div>
    </div>
  );
};

export default Drivers;
