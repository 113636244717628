import { Form as FormikForm, FormikProvider, useFormik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { Form as Formik } from 'react-bootstrap-formik';
import { useQueryClient } from 'react-query';
import { sendBroker } from '../../../api/AdminInvoicesAPI';
import { useInvoice } from '../../../api/InvoicesAPI';

export function SendBrokerModal({ show, invoiceId, handleClose }) {
  const { data: invoice } = useInvoice(invoiceId);
  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit({ email }) {
      const emails = email
        .split(',')
        .map((item) => item.trim())
        .filter(Boolean);

      return sendBroker(invoiceId, emails).then(() => {
        queryClient.invalidateQueries(['invoices']);
        handleClose();
      });
    },
  });

  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <FormikProvider value={formik}>
        <FormikForm>
          <Modal.Header
            closeButton
            style={{ background: '#396cae', color: '#fff' }}
          >
            <Modal.Title>
              <h3 className="p-0 m-0">Send Email</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="p-0 m-0">
              <b>Broker Email: </b>
              {invoice?.broker?.contactEmail}
            </p>

            <Form.Group className="mb-2">
              <Form.Label>
                <b>Email</b>
              </Form.Label>
              <Formik.Input name="email" placeholder="Enter Email" />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" className="invoice-btn">
              Send
            </Button>
          </Modal.Footer>
        </FormikForm>
      </FormikProvider>
    </Modal>
  );
}
