import Select from 'react-select';
import { useDrivers } from '../../../api/InvoicesAPI';

export function DriverSelect({ value, onChange }) {
  const { data = [] } = useDrivers();

  return (
    <Select
      options={data}
      onChange={onChange}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      value={data.find((item) => item.id === value)}
      theme={(theme) => ({
        ...theme,
        borderRadius: '.25rem',
        colors: {
          ...theme.colors,
          primary25: '#f3f3f3',
          primary: '#396cae',
        },
      })}
    />
  );
}
