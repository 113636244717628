const SET_CARRIERS = 'SET_CARRIERS';
const SET_IS_FETCHING = 'SET_IS_FETCHING';
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
const SET_FETCH_ERROR = 'SET_FETCH_ERROR';
const SET_CURRENT_CARRIER = 'SET_CURRENT_CARRIER';
const SET_AUTH = 'SET_AUTH';
const LOGOUT = 'LOGOUT';

const defaultState = {
  items: [],
  isFetching: true,
  currentPage: 0,
  perPage: 10,
  totalPages: 0,
  isFetchError: false,
  currentCarrier: null,
  isAuth: undefined,
};

export default function carrierReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_CARRIERS:
      return {
        ...state,
        items: action.payload.data,
        currentPage: action.payload.pageNumber,
        totalPages: action.payload.totalPages,
        isFetching: false,
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_FETCH_ERROR:
      return {
        ...state,
        isFetchError: action.payload,
      };
    case SET_CURRENT_CARRIER:
      return {
        ...state,
        currentCarrier: action.payload,
        isFetching: false,
        isFetchError: false,
        isAuth: true,
      };
    case SET_AUTH:
      return {
        ...state,
        isAuth: action.payload,
      };
    case LOGOUT:
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('invoice_filter');
      return {
        ...state,
        currentCarrier: null,
        isAuth: false,
      };
    default:
      return state;
  }
}

export const setCarriers = (repos) => ({ type: SET_CARRIERS, payload: repos });
export const setCurrentCarries = (about) => ({
  type: SET_CURRENT_CARRIER,
  payload: about,
});
export const setIsFetching = (bool) => ({
  type: SET_IS_FETCHING,
  payload: bool,
});
export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});
export const setFetchError = (bool) => ({
  type: SET_FETCH_ERROR,
  payload: bool,
});
export const setAuth = (bool) => ({ type: SET_AUTH, payload: bool });
export const logout = () => ({ type: LOGOUT });
