import {
  faArrowLeft,
  faArrowRight,
  faEdit,
  faFile,
  faFilter,
  faPlus,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { useInvoices } from '../../api/InvoicesAPI';
import '../../style/Invoices.scss';
import InvoiceFilterModal from './components/InvoiceFilterModal';
import { ModalInvoiceBatch } from './containers/ModalInvoiceBatch';

const currencyMoneyFormat = (num) => {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const Invoices = () => {
  const history = useHistory();

  const [searchBy, setSearchBy] = useState({
    value: 'reference',
    label: 'Reference',
  });
  const [searchValue, setSearchValue] = useState('');

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [filter, setFilter] = useState({});
  const [tab, setTab] = useState('Unsubmitted');
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const [showFilter, setShowFilter] = useState(false);
  const [showBatch, setShowBatch] = useState(false);

  const {
    data: { data = [], totalPages = 0 } = {},
    isLoading,
    refetch,
  } = useInvoices({
    size,
    page,
    statusGroup: tab,
    ...filter,
  });

  const handleSelect = (key) => {
    setTab(key);
  };

  function handleSearch() {
    setFilter((prev) => ({
      ...prev,
      [searchBy.value]: searchValue || undefined,
    }));
  }

  // tabs have different statuses
  useEffect(() => {
    setFilter((prev) => ({ ...prev, statusId: undefined }));
  }, [tab]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      reference: undefined,
      invoiceNumber: undefined,
      [searchBy.value]: searchValue || undefined,
    }));
  }, [searchBy]);

  return (
    <div
      className="ibox-content"
      onKeyDown={(event) => {
        if (event.key === 'Enter' && selectedInvoices.length > 0) {
          setShowBatch(true);
        }
      }}
    >
      <InvoiceFilterModal
        tab={tab}
        show={showFilter}
        value={filter}
        handleClose={() => setShowFilter(false)}
        onSubmit={(values) => {
          setShowFilter(false);
          setFilter(values);
        }}
      />

      <ModalInvoiceBatch
        show={showBatch}
        invoices={selectedInvoices}
        handleClose={() => setShowBatch(false)}
        onSuccess={() => {
          setShowBatch(false);
          setSelectedInvoices([]);
          void refetch();
        }}
      />

      <div className="row">
        <div className="col-lg-9 col-md-6 col-sm-12">
          <h2>Invoices</h2>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="input-group pull-right">
            <Button
              variant="outline-secondary"
              className="btn-default m-1"
              onClick={() => history.push('build-invoices')}
            >
              <FontAwesomeIcon icon={faFile} /> Build New Invoice
            </Button>
            <Button
              className="invoice-btn m-1"
              onClick={() => {
                if (selectedInvoices.length > 0) {
                  setShowBatch(true);
                } else {
                  alert('Please select invoices');
                }
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              &nbsp; Submit Batch
            </Button>
          </div>
        </div>
      </div>
      <div id="cnt_main_pnl_search">
        <div className="input-group">
          <Select
            value={searchBy}
            options={[
              { value: 'reference', label: 'Reference' },
              { value: 'invoiceNumber', label: 'Invoice Number' },
            ]}
            styles={{
              container: (provided) => ({
                ...provided,
                zIndex: 99,
                marginRight: '10px',
              }),
            }}
            onChange={(option) => {
              setSearchBy(option);
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: '.25rem',
              colors: {
                ...theme.colors,
                primary25: '#f3f3f3',
                primary: '#396cae',
              },
            })}
          />

          <input
            type="text"
            value={searchValue}
            id="cnt_main_txt_filter_text"
            placeholder="Search for an invoice"
            className="input form-control"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.stopPropagation();
                handleSearch();
              }
            }}
            onChange={(event) => {
              setSearchValue(event.target.value);
            }}
          />
          <div className="input-group-btn">
            <Button className="invoice-btn" onClick={handleSearch}>
              <FontAwesomeIcon icon={faSearch} />
              &nbsp;Search
            </Button>
          </div>
        </div>
      </div>

      <div className="clients-list" style={{ position: 'relative' }}>
        <div className="grid-group">
          <div className="btn-group">
            <Button
              variant="outline-secondary"
              className={
                Object.entries(filter).length === 0
                  ? 'btn-default btn-sm'
                  : 'invoice-btn btn-sm'
              }
              title="Filter the invoices in more detail."
              onClick={(e) => setShowFilter(true)}
            >
              <FontAwesomeIcon icon={faFilter} />
            </Button>
          </div>
          <div className="btn-group">
            <Button variant="outline-secondary" className="btn-default btn-sm">
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Button variant="outline-secondary" className="btn-default btn-sm">
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </div>
        <Tabs defaultActiveKey={tab} className="mt-2" onSelect={handleSelect}>
          <Tab eventKey="Unsubmitted" title="Unsubmitted">
            <MaterialTable
              page={page}
              totalCount={totalPages * size}
              onChangePage={(value) => {
                setPage(value);
              }}
              isLoading={isLoading}
              columns={[
                {
                  title: 'id',
                  field: 'id',
                  hidden: true,
                },
                {
                  title: 'Debtor',
                  field: 'broker',
                  width: '10%',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Invoice #',
                  field: 'invoice',
                  width: '5%',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Reference #',
                  field: 'reference',
                  width: '5%',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Amount',
                  field: 'amount',
                  width: '5%',
                  render: (rowData) => {
                    return <>{currencyMoneyFormat(rowData.amount)}</>;
                  },
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Pickup',
                  field: 'pickupLocation',
                  width: '30%',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Delivery',
                  field: 'deliveryLocation',
                  width: '25%',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Delivery Date',
                  field: 'deliveryDate',
                  width: '5%',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Driver',
                  field: 'driver',
                  width: '20%',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Created Date',
                  field: 'createAt',
                  width: '5%',
                  render: (rowData) => {
                    const curDate = new Date(rowData.createAt);
                    return <>{curDate.toISOString().split('T')[0]}</>;
                  },
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
              ]}
              style={{ boxShadow: 'none', fontSize: '13px' }}
              data={data}
              onChangeRowsPerPage={(value) => {
                setSize(value);
              }}
              options={{
                search: false,
                showTitle: false,
                selection: true,
                toolbar: false,
                pageSize: size,
                actionsColumnIndex: -1,
              }}
              selectionProps={({ id }) => {
                return { checked: selectedInvoices.find((x) => x.id === id) };
              }}
              onSelectionChange={(rows) => {
                setSelectedInvoices(rows);
              }}
              onRowClick={(evt, selectedRow) => {
                history.push(`/invoices/${selectedRow.id}`);
              }}
              actions={[
                {
                  position: 'row',
                  icon: () => <FontAwesomeIcon icon={faEdit} />,
                  tooltip: 'Edit',
                  onClick: (event, rowData) => {
                    history.push(`/build-invoices/${rowData.id}`);
                  },
                },
              ]}
            />
          </Tab>
          <Tab eventKey="In Processing" title="In Processing">
            <MaterialTable
              page={page}
              totalCount={totalPages * size}
              onChangePage={(value) => {
                setPage(value);
              }}
              isLoading={isLoading}
              columns={[
                {
                  title: 'id',
                  field: 'id',
                  hidden: true,
                },
                {
                  title: 'Status',
                  field: 'status',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Debtor',
                  field: 'broker',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Invoice #',
                  field: 'invoice',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Reference #',
                  field: 'reference',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Amount',
                  field: 'amount',
                  render: (rowData) => {
                    return <>{currencyMoneyFormat(rowData.amount)}</>;
                  },
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Pickup',
                  field: 'pickupLocation',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Delivery',
                  field: 'deliveryLocation',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Delivery Date',
                  field: 'deliveryDate',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Driver',
                  field: 'driver',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Advance Type',
                  field: 'paymentOptionName',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
              ]}
              style={{ boxShadow: 'none' }}
              data={data}
              onChangeRowsPerPage={(value) => {
                setSize(value);
              }}
              options={{
                search: false,
                showTitle: false,
                selection: false,
                toolbar: false,
                pageSize: size,
                actionsColumnIndex: -1,
              }}
              onRowClick={(evt, selectedRow) => {
                history.push(`/invoices/${selectedRow.id}`);
              }}
            />
          </Tab>
          <Tab eventKey="Requires Attention" title="Requires Attention">
            <MaterialTable
              page={page}
              totalCount={totalPages * size}
              onChangePage={(value) => {
                setPage(value);
              }}
              isLoading={isLoading}
              columns={[
                {
                  title: 'id',
                  field: 'id',
                  hidden: true,
                },
                {
                  title: 'Status',
                  field: 'status',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Debtor',
                  field: 'broker',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Invoice #',
                  field: 'invoice',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Reference #',
                  field: 'reference',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Amount',
                  field: 'amount',
                  render: (rowData) => {
                    return <>{currencyMoneyFormat(rowData.amount)}</>;
                  },
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Pickup',
                  field: 'pickupLocation',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Delivery',
                  field: 'deliveryLocation',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Delivery Date',
                  field: 'deliveryDate',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Driver',
                  field: 'driver',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Advance Type',
                  field: 'paymentOptionName',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
              ]}
              style={{ boxShadow: 'none' }}
              data={data}
              onChangeRowsPerPage={(value) => {
                setSize(value);
              }}
              options={{
                search: false,
                showTitle: false,
                selection: false,
                toolbar: false,
                pageSize: size,
                actionsColumnIndex: -1,
              }}
              onRowClick={(evt, selectedRow) => {
                history.push(`/invoices/${selectedRow.id}`);
              }}
            />
          </Tab>
          <Tab eventKey="In Dispute" title="In Dispute">
            <MaterialTable
              page={page}
              totalCount={totalPages * size}
              onChangePage={(value) => {
                setPage(value);
              }}
              isLoading={isLoading}
              columns={[
                {
                  title: 'id',
                  field: 'id',
                  hidden: true,
                },
                {
                  title: 'Status',
                  field: 'status',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Debtor',
                  field: 'broker',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Invoice #',
                  field: 'invoice',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Reference #',
                  field: 'reference',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Amount',
                  field: 'amount',
                  render: (rowData) => {
                    return <>{currencyMoneyFormat(rowData.amount)}</>;
                  },
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Pickup',
                  field: 'pickupLocation',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Delivery',
                  field: 'deliveryLocation',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Delivery Date',
                  field: 'deliveryDate',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Driver',
                  field: 'driver',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Advance Type',
                  field: 'paymentOptionName',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Reason',
                  field: 'reason',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
              ]}
              style={{ boxShadow: 'none' }}
              data={data}
              onChangeRowsPerPage={(value) => {
                setSize(value);
              }}
              options={{
                search: false,
                showTitle: false,
                selection: false,
                toolbar: false,
                pageSize: size,
                actionsColumnIndex: -1,
              }}
              onRowClick={(evt, selectedRow) => {
                history.push(`/invoices/${selectedRow.id}`);
              }}
            />
          </Tab>
          <Tab eventKey="Processed" title="Processed">
            <MaterialTable
              page={page}
              totalCount={totalPages * size}
              onChangePage={(value) => {
                setPage(value);
              }}
              isLoading={isLoading}
              columns={[
                {
                  title: 'id',
                  field: 'id',
                  hidden: true,
                },
                {
                  title: 'Debtor',
                  field: 'broker',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Invoice #',
                  field: 'invoice',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Reference #',
                  field: 'reference',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Amount',
                  field: 'amount',
                  render: (rowData) => {
                    return <>{currencyMoneyFormat(rowData.amount)}</>;
                  },
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Pickup',
                  field: 'pickupLocation',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Delivery',
                  field: 'deliveryLocation',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Delivery Date',
                  field: 'deliveryDate',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Driver',
                  field: 'driver',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
                {
                  title: 'Advance Type',
                  field: 'paymentOptionName',
                  headerStyle: {
                    fontWeight: '600',
                    padding: '0.5rem',
                  },
                },
              ]}
              style={{ boxShadow: 'none' }}
              data={data}
              onChangeRowsPerPage={(value) => {
                setSize(value);
              }}
              options={{
                search: false,
                showTitle: false,
                selection: false,
                toolbar: false,
                pageSize: size,
                actionsColumnIndex: -1,
              }}
              onRowClick={(evt, selectedRow) => {
                history.push(`/invoices/${selectedRow.id}`);
              }}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Invoices;
