import React from 'react';
import DropFileContainer from '../DropFileContainer';
import SignedDocuments from './SignedDocuments';

function CarrierSignedDocuments(props) {
  return (
    <div className="w-100">
      {props.id ? (
        <>
          <DropFileContainer id={props.id} />
          <SignedDocuments id={props.id} />
        </>
      ) : null}
    </div>
  );
}

export default CarrierSignedDocuments;
