import React from 'react';
import './Footer.scss';

const Footer = (props) => {
  return (
    <div className="footer">
      <div>Invoices Web Portal © 2022</div>
      <div className="pull-right">
        Version: <span id="lbl_version">1.0.0</span>
      </div>
    </div>
  );
};

export default Footer;
