import Select from 'react-select';
import { useInvoiceStatuses } from '../../../api/InvoicesAPI';

export function StatusSelect({ value, statusGroup, onChange }) {
  const { data = [] } = useInvoiceStatuses();

  return (
    <Select
      options={data}
      onChange={onChange}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      filterOption={(option) =>
        statusGroup ? option.data.statusGroup === statusGroup : true
      }
      value={data.find((item) => item.id === value)}
      theme={(theme) => ({
        ...theme,
        borderRadius: '.25rem',
        colors: {
          ...theme.colors,
          primary25: '#f3f3f3',
          primary: '#396cae',
        },
      })}
    />
  );
}
