import { Form, FormikProvider, useFormik } from 'formik';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { submitInvoices } from '../../../api/InvoicesAPI';
import * as Views from '../components/InvoiceSubmitModal';

const schema = yup.object({
  paymentOption: yup.number().required(),
});

export function ModalInvoiceBatchContent({
  handleClose,
  invoices = [],
  onSuccess,
}) {
  const queryClient = useQueryClient();
  const totalAmount = invoices.reduce(
    (acc, invoice) => acc + invoice.amount,
    0
  );
  const { paymentOptions } = useSelector(
    (state) => state.carrier.currentCarrier
  );

  const formik = useFormik({
    validationSchema: schema,
    initialValues: { paymentOption: paymentOptions?.[0]?.id || '' },
    onSubmit({ paymentOption }) {
      const invoicesID = invoices.map((invoice) => invoice.id);
      return submitInvoices(paymentOption, invoicesID).then(() => {
        queryClient.invalidateQueries(['invoices']);
        onSuccess();
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Views.InvoiceSubmitModalContent
          invoiceCount={invoices.length}
          totalAmount={totalAmount}
          handleClose={handleClose}
          paymentOptions={paymentOptions}
        />
      </Form>
    </FormikProvider>
  );
}

export function ModalInvoiceBatch({
  show,
  handleClose,
  invoices = [],
  onSuccess,
}) {
  return (
    <Views.InvoiceSubmitModal
      show={show && invoices.length}
      handleClose={handleClose}
    >
      <ModalInvoiceBatchContent
        invoices={invoices}
        onSuccess={onSuccess}
        handleClose={handleClose}
      />
    </Views.InvoiceSubmitModal>
  );
}
