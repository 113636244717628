import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {
  addBrokerAction,
  changeBrokerAction,
  deleteBrokerAction,
  getSelectedBrokerAction,
} from '../actions/broker';
import { stateOptions } from '../actions/directory';
import { logout } from '../reducers/currentReducer';

const loadStateOptions = async () => {
  const response = await stateOptions();
  const data = response.data;

  const optionList =
    data &&
    data.map((item) => ({
      value: item.id,
      label: `${item.state} (${item.abbreviation})`,
    }));
  return optionList;
};

const AdmBroker = ({ match }) => {
  const { id } = match.params;
  const isChange = id > -1;
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectState, setSelectState] = useState(null);
  const [changeBroker, setchangeBroker] = useState({});

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const getCorrectSelectState = (item, state) => {
    const abb = item.label.split(' ');
    if (abb[1].indexOf(state) > -1) return item;
  };

  useEffect(() => {
    const fetchData = async () => {
      const stateOptions = (await loadStateOptions()) || null;
      if (isChange) {
        const currentBroker = await getSelectedBrokerAction(id);
        if (currentBroker && currentBroker.status) dispatch(logout());
        else {
          const [selectedStateOption] = stateOptions.filter((item) =>
            getCorrectSelectState(item, currentBroker.state)
          );
          setSelectState(selectedStateOption);

          setchangeBroker(currentBroker);
          reset(currentBroker);
        }
      }
    };
    fetchData();
  }, [reset]);

  const handleChangeOptionState = async (newValue) => {
    setSelectState(newValue);
  };

  const handleClickCancel = async () => {
    setchangeBroker({});
    history.push('/manage-brokers');
  };

  const handleClickRemove = async () => {
    const conf = window.confirm(`Are you sure?`);
    if (conf) {
      await deleteBrokerAction(id);
      setchangeBroker({});
      history.push('/manage-brokers');
    }
  };

  const onSumbit = async ({ requesterName, requesterPhone, ...data }) => {
    const changeBrokerInfo = {
      ...changeBroker,
      ...data,
      requesterName: requesterName || null,
      requesterPhone: requesterPhone || null,
      stateId: selectState.value,
    };
    console.table(changeBrokerInfo);

    if (isChange) {
      const response = await changeBrokerAction(id, changeBrokerInfo);
      if (response) {
        alert('Changes were successful');
        history.push('/manage-brokers');
      }
    } else {
      const response = await addBrokerAction(changeBrokerInfo);
      if (response) {
        alert('Save successful');
        history.push('/manage-brokers');
      }
    }
  };

  return (
    <form className="ibox-content" onSubmit={handleSubmit(onSumbit)}>
      <div>
        <div className="row">
          <div className="col-lg-8 col-md-6 col-sm-12">
            <h2>{isChange ? 'Edit Broker' : 'New Broker'}</h2>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-group pull-right">
              <Button
                className="m-1"
                variant="light"
                onClick={handleClickCancel}
              >
                Cancel
              </Button>
              <Button className="invoice-btn m-1" type="submit">
                <FontAwesomeIcon icon={faSave} /> Save Broker
              </Button>
              {isChange ? (
                <Button
                  className="invoice-btn-two m-1"
                  onClick={handleClickRemove}
                >
                  {' '}
                  <FontAwesomeIcon icon={faTrash} /> Delete Broker
                </Button>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <hr />
          <div style={{ display: 'flex' }}>
            <div
              className="p-2"
              style={{ flexDirection: 'column', flexGrow: '1' }}
            >
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>
                    Debtor Company Name <span className="error-simbol" />
                  </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('debtorCompanyName', {
                    required: 'This is a required',
                    minLength: { value: 0, message: ' length is 0' },
                    maxLength: 50,
                  })}
                  placeholder="Enter Debtor Company Name"
                />
                {errors.debtorCompanyName && (
                  <p className="error">{errors.debtorCompanyName.message}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Address</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('address')}
                  placeholder="Enter Address"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>
                    City
                    <span className="error-simbol" />
                  </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('city', {
                    required: 'This is a required',
                    minLength: { value: 0, message: ' length is 0' },
                    maxLength: 100,
                  })}
                  placeholder="Enter City"
                />
                {errors.city && <p className="error">{errors.city.message}</p>}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>
                    Phone number <span className="error-simbol" />
                  </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('phone', {
                    required: 'This is a required',
                    minLength: { value: 0, message: ' length is 0' },
                    maxLength: 50,
                  })}
                  placeholder="Enter number"
                />
                {errors.phone && (
                  <p className="error">{errors.phone.message}</p>
                )}
              </Form.Group>
            </div>
            <div
              className="p-2"
              style={{ flexDirection: 'column', flexGrow: '1' }}
            >
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Contact Name</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('contactName')}
                  placeholder="Enter Contact Name"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Contact Email</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('contactEmail')}
                  placeholder="Enter Contact Email"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>
                    Credit Limit
                    <span className="error-simbol" />
                  </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('creditLimit', {
                    required: 'This is a required',
                    minLength: { value: 0, message: ' length is 0' },
                  })}
                  placeholder="Enter Credit Limit"
                />
                {errors.creditLimit && (
                  <p className="error">{errors.creditLimit.message}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Zip</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('zip')}
                  placeholder="Enter Zip Code"
                />
              </Form.Group>
            </div>
            <div
              className="p-2"
              style={{ flexDirection: 'column', flexGrow: '1' }}
            >
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Requester Name</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('requesterName', {
                    minLength: 2,
                    maxLength: 50,
                  })}
                  placeholder="Enter Requester Name"
                />
                {errors.requesterName && (
                  <p className="error">{errors.requesterName.message}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Requester Phone</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('requesterPhone', {
                    minLength: 2,
                    maxLength: 50,
                  })}
                  placeholder="Enter Requester Phone"
                />
                {errors.requesterPhone && (
                  <p className="error">{errors.requesterPhone.message}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>
                    State
                    <span className="error-simbol" />
                  </b>
                </Form.Label>
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadStateOptions}
                  defaultOptions
                  onChange={(value) => handleChangeOptionState(value)}
                  value={selectState}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: '.25rem',
                    colors: {
                      ...theme.colors,
                      primary25: '#f3f3f3',
                      primary: '#396cae',
                    },
                  })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>
                    MC Number <span className="error-simbol" />
                  </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('mcNumber', {
                    required: 'This is a required',
                    minLength: 2,
                    maxLength: 80,
                  })}
                  placeholder="Enter number"
                />
                {errors.mcNumber && (
                  <p className="error">{errors.mcNumber.message}</p>
                )}
              </Form.Group>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="p-2" style={{ flex: '3' }}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  <b>Comment</b>
                </Form.Label>
                <Form.Control as="textarea" rows={3} {...register('comment')} />
              </Form.Group>
            </div>
            <div style={{ flex: '1.25 1 0%', margin: '3rem 2rem' }}>
              <Form.Group
                className="mb-3"
                style={{ fontSize: '16px', display: 'inline-flex' }}
              >
                <Form.Check
                  type="checkbox"
                  label="Policy Checked"
                  {...register('policyChecked')}
                />
                <span className="error-simbol" />
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AdmBroker;
