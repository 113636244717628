import jwt_decode from 'jwt-decode';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { $authHost } from './config';

export async function submitInvoices(paymentOptionID, invoiceIDs) {
  await $authHost.post('/api/flow/carrier/ready_submitted', {
    invoiceIdList: invoiceIDs,
  });
  await $authHost.post('/api/flow/carrier/submitted', {
    paymentOptionId: paymentOptionID,
  });
}

export function searchInvoice(number) {
  return $authHost.get('/api/invoice/search', {
    params: { number },
  });
}

export function chargeInvoice(invoiceId, payload) {
  return $authHost.post(`/api/invoice/${invoiceId}/clone`, payload);
}

export function useInvoiceCache() {
  const client = useQueryClient();
  return useMemo(() => {
    return {
      invalidateInvoices: () => client.invalidateQueries('invoices'),
    };
  }, [client]);
}

export function useInvoices(params) {
  return useQuery(
    ['invoices', 'list', { params }],
    async () => {
      const { data } = await $authHost.get('/api/invoice', {
        params,
      });
      return data;
    },
    { keepPreviousData: false, refetchOnMount: 'always' }
  );
}

export function useCharges() {
  return useQuery(
    ['invoices', 'type_of_charge'],
    async () => {
      const { data } = await $authHost.get('/api/directory/type_of_charge');
      return data.data;
    },
    { keepPreviousData: false }
  );
}

export function useDrivers() {
  const token = sessionStorage.getItem('token');
  const jwtInfo = jwt_decode(token);
  return useQuery(['invoices', 'drivers'], async () => {
    const { data } = await $authHost.get(`/api/carrier/${jwtInfo.id}/driver`);
    return data.data;
  });
}

export function useInvoiceStatuses() {
  return useQuery(['invoices', 'statuses'], async () => {
    const { data } = await $authHost.get(`/api/directory/invoice_status`);
    return data.data;
  });
}

export function useInvoice(id) {
  return useQuery(
    ['invoices', 'item', { id }],
    async () => {
      const { data } = await $authHost.get(`/api/invoice/${id}`);
      return data;
    },
    { enabled: !!id }
  );
}
