import React from 'react';
import { useDropzone } from 'react-dropzone';
import fileIcon from '../../../assets/file.png';

const styleZone = {
  border: '2px dashed  hsl(0, 0%, 80%)',
  padding: '3rem',
  marginBottom: '1rem',
  textAlign: 'center',
  color: '#676a6c',
  fontSize: '16px',
};

const DropFileInvoice = ({ file, onDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop,
  });

  return (
    <section className="container-fluid">
      <div className="form-uplad w-100">
        {file ? (
          <div className="files-upload mb-5">
            <div className="col-sm-1">
              <img src={fileIcon} style={{ width: '2.5rem' }} />
            </div>
          </div>
        ) : (
          <div {...getRootProps({ className: 'dropzone', style: styleZone })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default DropFileInvoice;
