import { Button } from 'react-bootstrap';
import { useInvoiceHistory } from '../../../api/AdminInvoicesAPI';
import { diffDate } from '../../../utils/DateUtils';
import InvoiceActivityItem from '../components/InvoiceActivityItem';

function InvoiceActivityTab({ invoice, onAddComment }) {
  const { data } = useInvoiceHistory(invoice.id);
  return (
    <div className="d-flex justify-content-between">
      <div className="w-25">
        {data?.data
          .slice()
          .sort((a, b) => diffDate(b.date, a.date))
          .map((item, index) => (
            <InvoiceActivityItem
              key={index}
              message={invoice.message}
              activity={item}
              isCompleted={index === 0}
            />
          ))}
      </div>

      <div>
        <Button
          variant="outline-secondary"
          className="btn-default mt-1"
          onClick={onAddComment}
        >
          Add comment
        </Button>
      </div>
    </div>
  );
}

export default InvoiceActivityTab;
